.rc-slider-tooltip {
  z-index: 9999; }

.create-budget-dialog > div:last-child > div {
  max-height: 100%; }

.create-budget-dialog .dialog-header-create {
  padding: 13px 26px 12px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .create-budget-dialog .dialog-header-create svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem; }
    .create-budget-dialog .dialog-header-create svg:hover {
      transform: scale(1.1); }

.create-budget-dialog .dialog-content-info {
  padding: 0px 26px; }

.create-budget-dialog ::-webkit-scrollbar {
  width: 6px; }

.create-budget-dialog ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #757575; }

.create-budget-dialog ::-webkit-scrollbar-track {
  border-radius: 10px; }

form.form-budget-monthly {
  position: relative;
  z-index: 1;
  overflow: auto;
  overflow-x: hidden; }
  form.form-budget-monthly > div:first-child {
    min-width: 600px;
    min-height: 345px;
    max-width: 600px;
    padding: initial;
    overflow-y: unset; }
  form.form-budget-monthly .col-2 {
    width: 550px;
    display: flex;
    justify-content: flex-start; }
    form.form-budget-monthly .col-2:first-child {
      margin-right: 15px; }
    form.form-budget-monthly .col-2 .text-field .label-text-field > div {
      font-size: 25px; }
  form.form-budget-monthly .form-icon {
    font-size: 2.3em;
    padding-right: 10px;
    padding-top: 5px;
    color: inherit;
    opacity: 0.54; }
    form.form-budget-monthly .form-icon.mdi-tag {
      padding-top: 8px; }
    form.form-budget-monthly .form-icon.mdi-calculator {
      cursor: pointer;
      transition: 0.1s; }
      form.form-budget-monthly .form-icon.mdi-calculator:hover {
        opacity: 1; }
  form.form-budget-monthly .value-budget {
    float: right;
    margin-right: 5px; }
  form.form-budget-monthly .value-remaining-budget {
    float: right;
    margin-right: 5px;
    margin-top: 7px; }
  form.form-budget-monthly .categories-label {
    font-size: 0.8em; }
  form.form-budget-monthly .chosen-categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
    padding-right: 16px;
    max-height: 202px;
    overflow-y: auto; }
    form.form-budget-monthly .chosen-categories .chosen-category {
      color: #ffffff;
      margin: 0px 4px 2px 4px;
      width: auto; }
      form.form-budget-monthly .chosen-categories .chosen-category svg {
        color: rgba(255, 255, 255, 0.5); }
  form.form-budget-monthly .content-categories {
    overflow-y: auto;
    position: relative;
    flex: 1 1 auto;
    height: 130px;
    font-family: "Maven Pro"; }
  form.form-budget-monthly .content-alert {
    width: 100%; }
    form.form-budget-monthly .content-alert .alert-form-group {
      align-items: center; }
      form.form-budget-monthly .content-alert .alert-form-group .alert-control span:last-of-type {
        font-size: 24px; }
  form.form-budget-monthly .item-category {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    margin-bottom: 15px;
    font-family: "Maven Pro";
    justify-content: space-between;
    align-items: baseline; }
    form.form-budget-monthly .item-category.remaining-budget {
      display: block;
      margin-top: 10px;
      margin-bottom: 0;
      width: 100%; }
      form.form-budget-monthly .item-category.remaining-budget > span {
        margin-left: 10px;
        font-family: "Maven Pro"; }
    form.form-budget-monthly .item-category .icon-avatar {
      margin-right: 10px; }
    form.form-budget-monthly .item-category-label {
      display: block;
      align-items: center;
      width: 355px; }
      form.form-budget-monthly .item-category-label .item-description > span {
        font-family: "Maven Pro"; }
      form.form-budget-monthly .item-category-label .item-description {
        display: inline-grid; }
        form.form-budget-monthly .item-category-label .item-description .amount-spent-by-category {
          font-size: 12px;
          color: #777777; }
    form.form-budget-monthly .item-category .icon-avatar {
      width: 40px;
      height: 40px;
      display: inline-flex;
      position: relative;
      font-size: 1.25rem;
      flex-shrink: 0;
      align-items: center;
      user-select: none;
      border-radius: 50%;
      justify-content: center; }
      form.form-budget-monthly .item-category .icon-avatar span {
        color: #fff; }
      form.form-budget-monthly .item-category .icon-avatar .remaining-budget {
        color: #000;
        opacity: 0.5; }
  form.form-budget-monthly .item-sub-category {
    font-size: 20px;
    font-weight: 500;
    margin-left: 30px;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: baseline; }
    form.form-budget-monthly .item-sub-category-label .item-description > span {
      font-family: "Maven Pro"; }
    form.form-budget-monthly .item-sub-category-label .item-description {
      display: inline-grid;
      margin-left: 5px; }
      form.form-budget-monthly .item-sub-category-label .item-description .amount-spent-by-category {
        font-size: 12px;
        color: #777777; }
    form.form-budget-monthly .item-sub-category-label .icon-avatar {
      width: 25px;
      height: 25px;
      display: inline-flex;
      position: relative;
      font-size: 1.25rem;
      flex-shrink: 0;
      align-items: center;
      user-select: none;
      border-radius: 50%;
      justify-content: center;
      top: 5px; }
      form.form-budget-monthly .item-sub-category-label .icon-avatar span {
        color: #fff; }
  form.form-budget-monthly .col {
    width: 550px;
    display: flex;
    justify-content: flex-start; }
  form.form-budget-monthly .rc-slider-handle {
    border: solid 2px #fff;
    background-color: #7C4DFF;
    width: 20px;
    height: 20px;
    margin-top: -8px; }
  form.form-budget-monthly .rc-slider-track {
    background-color: #7C4DFF;
    height: 5px; }
  form.form-budget-monthly .value-total-goal {
    width: 200px;
    margin: 0 auto; }
  form.form-budget-monthly .row-drawer > div > span {
    margin-left: 0 !important; }
  form.form-budget-monthly .row-drawer .label-category {
    align-items: center;
    display: flex;
    margin-bottom: 10px; }
    form.form-budget-monthly .row-drawer .label-category svg {
      margin-right: 8px; }
  form.form-budget-monthly .row {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start; }
    form.form-budget-monthly .row:last-child {
      margin-bottom: 0;
      display: contents; }
    form.form-budget-monthly .row .step-content {
      width: 90%;
      margin: 10px auto 24px; }
      form.form-budget-monthly .row .step-content > div {
        padding: 0;
        margin-left: -15px; }
    form.form-budget-monthly .row .content {
      padding: 13px 26px 12px 26px;
      font-family: "Maven Pro"; }
      form.form-budget-monthly .row .content .container-input {
        margin: 20px 0px; }
        form.form-budget-monthly .row .content .container-input label {
          display: flex;
          justify-content: center;
          align-items: center; }
          form.form-budget-monthly .row .content .container-input label svg {
            margin-right: 10px; }
      form.form-budget-monthly .row .content .budget-value-total {
        display: grid;
        justify-content: center;
        text-align: center;
        margin: 10px 0 25px;
        width: 100%; }
        form.form-budget-monthly .row .content .budget-value-total span + span {
          font-size: 30px !important;
          font-weight: bold; }
          form.form-budget-monthly .row .content .budget-value-total span + span .plus-value {
            color: #2e7d32;
            font-size: 20px; }
        form.form-budget-monthly .row .content .budget-value-total > span:last-of-type {
          font-size: 15px;
          color: #828282;
          font-weight: bold;
          font-family: "Maven Pro"; }
      form.form-budget-monthly .row .content .resume-value-initial-question span {
        display: block; }
      form.form-budget-monthly .row .content .resume-value-initial-question span:first-child {
        padding-bottom: 10px; }
      form.form-budget-monthly .row .content .help {
        cursor: pointer;
        padding-bottom: 15px;
        align-items: center;
        justify-content: space-between;
        display: inline-flex; }
        form.form-budget-monthly .row .content .help svg {
          margin-right: 10px; }
  form.form-budget-monthly .dialog-actions {
    margin: 8px 4px; }
  form.form-budget-monthly .actions {
    margin-right: 20px;
    margin-bottom: 10px; }
    form.form-budget-monthly .actions .btn-back {
      margin-right: 20px; }
    form.form-budget-monthly .actions .btn-submit-primary {
      background: linear-gradient(to right, #7c4dff, #6515dd); }
      form.form-budget-monthly .actions .btn-submit-primary:disabled {
        background: rgba(124, 77, 255, 0.5);
        color: rgba(255, 255, 255, 0.3); }
  form.form-budget-monthly .select-field {
    cursor: pointer;
    width: 100%; }
    form.form-budget-monthly .select-field > div {
      width: 100%; }
    form.form-budget-monthly .select-field ul {
      max-height: 250px; }
    form.form-budget-monthly .select-field .select-type {
      position: relative;
      margin-top: 0;
      padding: 0;
      width: 100%; }
  form.form-budget-monthly .text-field {
    width: 100%; }
    form.form-budget-monthly .text-field > div {
      width: 100%; }
      form.form-budget-monthly .text-field > div > p {
        width: 183px; }
  form.form-budget-monthly .value-total-goal .text-field > div {
    width: 100%; }
    form.form-budget-monthly .value-total-goal .text-field > div > div > input {
      text-align: center;
      font-size: 22px; }
  form.form-budget-monthly .icon-field.mdi {
    font-size: 1.4em;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-top: 18px;
    color: rgba(0, 0, 0, 0.54); }
  form.form-budget-monthly .icon-field.icon-category {
    margin-bottom: 5px;
    margin-right: 10px;
    margin-top: 12px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    form.form-budget-monthly .icon-field.icon-category:before {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      font-size: 18px;
      color: #ffffff; }
