.rc-slider-tooltip {
  z-index: 9999; }
  .rc-slider-tooltip .rc-slider-tooltip-inner {
    padding: 6px !important;
    border-radius: 15px !important; }

.dialog-edit-budget-total > div:last-child > div {
  max-height: 100%; }

form.form-budget-edit-total {
  position: relative;
  z-index: 1;
  padding: 20px; }
  form.form-budget-edit-total > div:first-child {
    min-width: 530px;
    min-height: 345px;
    max-width: 600px;
    padding: initial;
    overflow-y: unset; }
  form.form-budget-edit-total .rc-slider-handle {
    border: solid 2px #fff;
    background-color: #7C4DFF;
    width: 20px;
    height: 20px;
    margin-top: -8px; }
  form.form-budget-edit-total .rc-slider-track {
    background-color: #7C4DFF;
    height: 5px; }
  form.form-budget-edit-total .col-2 {
    width: calc(50% - 15px);
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    form.form-budget-edit-total .col-2:first-child {
      margin-right: 15px; }
    form.form-budget-edit-total .col-2:last-child {
      margin-left: 15px; }
  form.form-budget-edit-total .row-drawer > div > span {
    margin-left: 0 !important; }
  form.form-budget-edit-total button {
    min-width: 140px;
    margin: 0; }
    form.form-budget-edit-total button#btn-save-and-continue {
      display: none; }
      form.form-budget-edit-total button#btn-save-and-continue:disabled {
        background-color: transparent;
        color: rgba(124, 77, 255, 0.2); }
    form.form-budget-edit-total button.btn-submit-primary {
      background: linear-gradient(to right, #7c4dff, #6515dd); }
      form.form-budget-edit-total button.btn-submit-primary:disabled {
        background: rgba(124, 77, 255, 0.5);
        color: rgba(255, 255, 255, 0.3); }
  form.form-budget-edit-total .row {
    width: 100%;
    margin-bottom: 20px;
    justify-content: flex-start; }
    form.form-budget-edit-total .row.select-auto {
      display: block; }
      form.form-budget-edit-total .row.select-auto .col > .row > .col {
        display: flex; }
    form.form-budget-edit-total .row:last-child {
      margin-bottom: 0; }
  form.form-budget-edit-total .form-icon {
    font-size: 2.3em;
    padding-right: 10px;
    padding-top: 5px;
    color: inherit;
    opacity: 0.54; }
    form.form-budget-edit-total .form-icon.mdi-tag {
      padding-top: 8px; }
    form.form-budget-edit-total .form-icon.mdi-calculator {
      cursor: pointer;
      transition: 0.1s; }
      form.form-budget-edit-total .form-icon.mdi-calculator:hover {
        opacity: 1; }
  form.form-budget-edit-total .alert-messages {
    display: flex;
    color: #e57373;
    font-size: 15px;
    flex-wrap: wrap; }
    form.form-budget-edit-total .alert-messages span {
      margin-bottom: 10px; }
  form.form-budget-edit-total .select-field {
    cursor: pointer;
    width: 100%; }
    form.form-budget-edit-total .select-field > div {
      width: 100%; }
    form.form-budget-edit-total .select-field ul {
      max-height: 250px; }
    form.form-budget-edit-total .select-field .select-type {
      position: relative;
      margin-top: 0;
      padding: 0;
      width: 100%; }
  form.form-budget-edit-total .text-field > div {
    width: 100%; }
  form.form-budget-edit-total .icon-field.mdi {
    font-size: 1.4em;
    padding: 0 !important;
    margin: 0 10px 0 0 !important;
    color: inherit; }
  form.form-budget-edit-total .icon-field.icon-category {
    margin-bottom: 5px;
    margin-right: 10px;
    margin-top: 12px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    form.form-budget-edit-total .icon-field.icon-category:before {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      font-size: 18px;
      color: #ffffff; }

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 26px 12px 26px; }
  .dialog-header svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem; }
    .dialog-header svg:hover {
      transform: scale(1.1); }

.form-budget-edit-total {
  overflow: auto; }
  .form-budget-edit-total::-webkit-scrollbar {
    width: 6px;
    background-color: transparent; }
  .form-budget-edit-total::-webkit-scrollbar-thumb {
    border-radius: 10px; }
  .form-budget-edit-total::-webkit-scrollbar-track {
    border-radius: 10px; }
  .form-budget-edit-total .content-categories,
  .form-budget-edit-total .content {
    justify-content: space-between;
    display: flex;
    align-items: center; }
    .form-budget-edit-total .content-categories label,
    .form-budget-edit-total .content label {
      display: flex;
      align-items: center; }
      .form-budget-edit-total .content-categories label svg,
      .form-budget-edit-total .content label svg {
        margin-right: 5px; }
