.import-data .toolbar-actions {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.import-data .toolbar-left {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-top: -20px; }

.import-data .content-import-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: 20px; }
  .import-data .content-import-data.first-content {
    margin-top: -40px; }

.import-data .content-btn-actions {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1024px; }
  .import-data .content-btn-actions button {
    margin-left: 10px;
    box-shadow: 1.5px 1.5px 1px 0px rgba(0, 0, 0, 0.3);
    margin-top: -42px;
    border-radius: 30px; }
    .import-data .content-btn-actions button .mdi {
      margin-right: 5px;
      font-size: 1.2em; }
    .import-data .content-btn-actions button.light {
      background-color: #fff;
      color: #6515DD; }
      .import-data .content-btn-actions button.light:hover {
        background-color: #f5f5f5; }
    .import-data .content-btn-actions button.dark {
      background-color: #333;
      color: #7C4DFF; }
      .import-data .content-btn-actions button.dark:hover {
        background-color: #474747; }
  .import-data .content-btn-actions.data-imported button {
    margin-top: 3px;
    margin-right: 0; }
    .import-data .content-btn-actions.data-imported button.light {
      background-color: #fff; }
      .import-data .content-btn-actions.data-imported button.light:hover {
        background-color: #f5f5f6; }
    .import-data .content-btn-actions.data-imported button.dark {
      background-color: #333; }
      .import-data .content-btn-actions.data-imported button.dark:hover {
        background-color: #434343; }

.import-data .import-data-paper {
  margin: 30px;
  margin-top: 10px;
  padding: 20px;
  border-radius: 15px;
  max-width: 1024px;
  width: 100%;
  box-sizing: border-box; }

.import-data .empty-import {
  display: flex;
  flex-direction: column; }
  .import-data .empty-import .mdi {
    font-size: 7em;
    margin-top: 40px;
    color: #9e9e9e; }
  .import-data .empty-import h2 {
    margin: 100px 0;
    color: #9e9e9e; }

.import-data .import-bank-content .row-title,
.import-data .import-excel-content .row-title {
  margin-bottom: 25px; }

.import-data .import-bank-content .row-know-how,
.import-data .import-bank-content .row-title,
.import-data .import-excel-content .row-know-how,
.import-data .import-excel-content .row-title {
  display: flex;
  width: 100%;
  justify-content: flex-start; }

.import-data .import-bank-content a,
.import-data .import-excel-content a {
  color: #7c4dff;
  text-decoration: none;
  text-align: left; }
  .import-data .import-bank-content a span,
  .import-data .import-excel-content a span {
    font-size: 1em; }
    .import-data .import-bank-content a span.mdi,
    .import-data .import-excel-content a span.mdi {
      margin-right: 4px; }

.import-data .import-bank-content .tabs-import-data,
.import-data .import-excel-content .tabs-import-data {
  margin-top: 20px;
  border-bottom: 1px solid #e1e1e1; }

.import-data .import-bank .row,
.import-data .import-excel-content .row {
  display: flex;
  margin-top: 30px;
  justify-content: flex-start; }
  .import-data .import-bank .row.row-form,
  .import-data .import-excel-content .row.row-form {
    align-items: flex-end; }

.import-data .import-bank .row-action,
.import-data .import-excel-content .row-action {
  display: flex;
  justify-content: flex-end; }
  .import-data .import-bank .row-action button,
  .import-data .import-excel-content .row-action button {
    background-color: #7c4dff;
    background: linear-gradient(to right, #7c4dff, #6515dd);
    border-radius: 30px;
    color: #ffffff;
    margin-left: 50px;
    width: 150px;
    height: 32px;
    padding: 0; }
    .import-data .import-bank .row-action button.btn-send:disabled,
    .import-data .import-excel-content .row-action button.btn-send:disabled {
      background-color: #e1e1e1; }
    .import-data .import-bank .row-action button span,
    .import-data .import-excel-content .row-action button span {
      width: 100%;
      height: 32px; }

.import-data .import-bank .form-control,
.import-data .import-excel-content .form-control {
  width: 100%; }
  .import-data .import-bank .form-control > div,
  .import-data .import-excel-content .form-control > div {
    text-align: left;
    width: 100%; }

.import-data .import-bank .form-import-file,
.import-data .import-excel-content .form-import-file {
  height: 32px;
  background: transparent;
  color: #7c4dff;
  border: 2px solid;
  padding: 0;
  margin-left: 50px;
  width: 200px;
  border-radius: 30px; }
  .import-data .import-bank .form-import-file.light:hover,
  .import-data .import-excel-content .form-import-file.light:hover {
    background-color: #bfbfbf; }
  .import-data .import-bank .form-import-file.dark:hover,
  .import-data .import-excel-content .form-import-file.dark:hover {
    background-color: #404040; }
  .import-data .import-bank .form-import-file span,
  .import-data .import-excel-content .form-import-file span {
    height: 32px; }
  .import-data .import-bank .form-import-file label,
  .import-data .import-excel-content .form-import-file label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875em; }
  .import-data .import-bank .form-import-file input,
  .import-data .import-excel-content .form-import-file input {
    opacity: 0;
    position: absolute;
    z-index: -1; }

.import-data .import-bank .file-name-ofx,
.import-data .import-excel-content .file-name-ofx {
  margin-left: 20px;
  height: 36px;
  display: flex;
  align-items: center; }

.import-data .transactions-bank-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  max-width: 1024px;
  width: 100%; }
  .import-data .transactions-bank-title span.transaction-title {
    font-size: 1.2em;
    font-weight: 500; }
  .import-data .transactions-bank-title .content-btn-actions {
    margin-left: auto;
    width: auto; }

.import-data .import-data-transaction-card {
  max-width: 1024px;
  width: 100%; }
  .import-data .import-data-transaction-card .transaction-card-register {
    widows: 100%;
    padding: 20px;
    padding-left: 0;
    box-sizing: border-box;
    margin: 10px 0px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: flex-start;
    border-radius: 15px; }
    .import-data .import-data-transaction-card .transaction-card-register.light {
      background-color: #fff; }
    .import-data .import-data-transaction-card .transaction-card-register.dark {
      background-color: #333; }
    .import-data .import-data-transaction-card .transaction-card-register .col-data {
      display: flex;
      flex-direction: column;
      width: calc(100% - 60px);
      margin-left: 12px; }
    .import-data .import-data-transaction-card .transaction-card-register .row:last-child {
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0; }
      .import-data .import-data-transaction-card .transaction-card-register .row:last-child .col-likely-duplication {
        margin-right: auto;
        display: flex;
        align-items: center; }
        .import-data .import-data-transaction-card .transaction-card-register .row:last-child .col-likely-duplication span {
          color: #ff8f00; }
        .import-data .import-data-transaction-card .transaction-card-register .row:last-child .col-likely-duplication .mdi {
          font-size: 1.4em;
          font-weight: 500; }
    .import-data .import-data-transaction-card .transaction-card-register .row .text-field {
      width: 100%; }
    .import-data .import-data-transaction-card .transaction-card-register .row .btn-add {
      background-color: #7c4dff;
      background: linear-gradient(to right, #7c4dff, #6515dd);
      border-radius: 30px;
      color: #ffffff; }
    .import-data .import-data-transaction-card .transaction-card-register .row .btn-cancel {
      background-color: #919191;
      border-radius: 30px;
      color: #ffffff;
      margin-right: 10px; }
    .import-data .import-data-transaction-card .transaction-card-register .row .select-field {
      width: 100%; }
      .import-data .import-data-transaction-card .transaction-card-register .row .select-field > div {
        width: 100%; }
        .import-data .import-data-transaction-card .transaction-card-register .row .select-field > div > div {
          text-align: left; }

.import-data .row {
  display: flex;
  margin-bottom: 16px; }
  .import-data .row .col-2,
  .import-data .row .col-small,
  .import-data .row .col-large {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start; }
  .import-data .row .col-2 {
    width: calc(50% - 10px); }
  .import-data .row .col-small {
    width: calc(25% - 10px); }
  .import-data .row .col-large {
    width: calc(75% - 10px); }

.import-data .info-credit-card-selected {
  display: flex;
  margin-left: 30px; }
  .import-data .info-credit-card-selected > div {
    margin-left: 16px; }
    .import-data .info-credit-card-selected > div span:first-child {
      font-weight: 500; }
  .import-data .info-credit-card-selected span {
    font-size: 1.2em; }
