.select-autocomplete {
  width: 100%;
  margin-top: -4px;
  z-index: 30;
  position: relative; }
  .select-autocomplete .form-control-autocomplete {
    width: 100%;
    margin-bottom: 5px; }
    .select-autocomplete .form-control-autocomplete span {
      font-family: "Noto Sans"; }
    .select-autocomplete .form-control-autocomplete .input-select-autocomplete .Select-value {
      font-family: "Noto Sans"; }
    .select-autocomplete .form-control-autocomplete .Select-clear-zone {
      display: none; }

.item-color {
  width: 30px;
  height: 30px; }

.new-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer; }
  .new-data span {
    opacity: 1;
    color: #1d1d1d; }
    .new-data span:first-child {
      font-size: 0.8em; }
    .new-data span:last-child {
      font-weight: 600; }

.option-subcategory {
  display: flex;
  align-items: center; }
  .option-subcategory span:first-child {
    font-size: 1.5em;
    margin-right: 10px;
    padding-left: 5px; }
