.mdi-1:before {
  content: "\F5F2"; }

.mdi-2:before {
  content: "\F10B"; }

.mdi-3:before {
  content: "\F53F"; }

.mdi-4:before {
  content: "\F072"; }

.mdi-5:before {
  content: "\F092"; }

.mdi-6:before {
  content: "\F5F6"; }

.mdi-7:before {
  content: "\F2DC"; }

.mdi-8:before {
  content: "\F5DA"; }

.mdi-9:before {
  content: "\F114"; }

.mdi-10:before {
  content: "\F2A1"; }

.mdi-11:before {
  content: "\F535"; }

.mdi-12:before {
  content: "\F539"; }

.mdi-13:before {
  content: "\F1D8"; }

.mdi-14:before {
  content: "\F01D"; }

.mdi-15:before {
  content: "\F68E"; }

.mdi-16:before {
  content: "\F070"; }

.mdi-17:before {
  content: "\F076"; }

.mdi-18:before {
  content: "\F0A3"; }

.mdi-19:before {
  content: "\F0B9"; }

.mdi-20:before {
  content: "\F0BA"; }

.mdi-21:before {
  content: "\F0D6"; }

.mdi-22:before {
  content: "\F0E2"; }

.mdi-23:before {
  content: "\F0E7"; }

.mdi-24:before {
  content: "\F0EB"; }

.mdi-25:before {
  content: "\F0EC"; }

.mdi-26:before {
  content: "\F0F6"; }

.mdi-27:before {
  content: "\F0FC"; }

.mdi-28:before {
  content: "\F100"; }

.mdi-29:before {
  content: "\F10A"; }

.mdi-30:before {
  content: "\F110"; }

.mdi-31:before {
  content: "\F117"; }

.mdi-32:before {
  content: "\F11B"; }

.mdi-33:before {
  content: "\F120"; }

.mdi-34:before {
  content: "\F127"; }

.mdi-35:before {
  content: "\F144"; }

.mdi-36:before {
  content: "\F146"; }

.mdi-37:before {
  content: "\F14D"; }

.mdi-38:before {
  content: "\F176"; }

.mdi-39:before {
  content: "\F188"; }

.mdi-40:before {
  content: "\F19B"; }

.mdi-41:before {
  content: "\F1C4"; }

.mdi-42:before {
  content: "\F1C8"; }

.mdi-43:before {
  content: "\F1E0"; }

.mdi-44:before {
  content: "\F1E6"; }

.mdi-45:before {
  content: "\F1EE"; }

.mdi-46:before {
  content: "\F1F2"; }

.mdi-47:before {
  content: "\F1F3"; }

.mdi-48:before {
  content: "\F1F7"; }

.mdi-49:before {
  content: "\F215"; }

.mdi-50:before {
  content: "\F230"; }

.mdi-51:before {
  content: "\F240"; }

.mdi-52:before {
  content: "\F24A"; }

.mdi-53:before {
  content: "\F27C"; }

.mdi-54:before {
  content: "\F290"; }

.mdi-55:before {
  content: "\F297"; }

.mdi-56:before {
  content: "\F298"; }

.mdi-57:before {
  content: "\F29B"; }

.mdi-58:before {
  content: "\F2C4"; }

.mdi-59:before {
  content: "\F2C8"; }

.mdi-60:before {
  content: "\F2D1"; }

.mdi-61:before {
  content: "\F2E0"; }

.mdi-62:before {
  content: "\F2E3"; }

.mdi-63:before {
  content: "\F2E8"; }

.mdi-64:before {
  content: "\F5CF"; }

.mdi-65:before {
  content: "\F2F3"; }

.mdi-66:before {
  content: "\F306"; }

.mdi-67:before {
  content: "\F6B4"; }

.mdi-68:before {
  content: "\F64F"; }

.mdi-69:before {
  content: "\F335"; }

.mdi-70:before {
  content: "\F34D"; }

.mdi-71:before {
  content: "\F356"; }

.mdi-72:before {
  content: "\F370"; }

.mdi-73:before {
  content: "\F37C"; }

.mdi-74:before {
  content: "\F387"; }

.mdi-75:before {
  content: "\F395"; }

.mdi-76:before {
  content: "\F39E"; }

.mdi-77:before {
  content: "\F39A"; }

.mdi-78:before {
  content: "\F602"; }

.mdi-79:before {
  content: "\F402"; }

.mdi-80:before {
  content: "\F403"; }

.mdi-81:before {
  content: "\F405"; }

.mdi-82:before {
  content: "\F41D"; }

.mdi-83:before {
  content: "\F428"; }

.mdi-84:before {
  content: "\F46E"; }

.mdi-85:before {
  content: "\F473"; }

.mdi-86:before {
  content: "\F474"; }

.mdi-87:before {
  content: "\F49C"; }

.mdi-88:before {
  content: "\F4A6"; }

.mdi-89:before {
  content: "\F4B8"; }

.mdi-90:before {
  content: "\F4C7"; }

.mdi-91:before {
  content: "\F4D3"; }

.mdi-92:before {
  content: "\F4D9"; }

.mdi-93:before {
  content: "\F4DC"; }

.mdi-94:before {
  content: "\F4DE"; }

.mdi-95:before {
  content: "\F4DF"; }

.mdi-96:before {
  content: "\F4FF"; }

.mdi-97:before {
  content: "\F513"; }

.mdi-98:before {
  content: "\F519"; }

.mdi-99:before {
  content: "\F529"; }

.mdi-100:before {
  content: "\F53B"; }

.mdi-101:before {
  content: "\F531"; }

.mdi-102:before {
  content: "\F565"; }

.mdi-103:before {
  content: "\F57C"; }

.mdi-104:before {
  content: "\F584"; }

.mdi-105:before {
  content: "\F589"; }

.mdi-106:before {
  content: "\F5B7"; }

.mdi-107:before {
  content: "\F5B5"; }

.mdi-108:before {
  content: "\F59F"; }

.budgets {
  padding: 0; }
  .budgets button.change-tab {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: none; }
    .budgets button.change-tab h2:before {
      position: absolute;
      right: 5px; }
    .budgets button.change-tab svg {
      color: #fff; }
  .budgets .toolbar-actions {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .budgets .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .budgets .content .content-budgets {
      width: 100%;
      justify-content: space-between;
      display: flex !important;
      flex-wrap: wrap; }
      @media (max-width: 1330px) {
        .budgets .content .content-budgets {
          flex-direction: column-reverse; } }
      .budgets .content .content-budgets .MuiDialog-paperScrollPaper {
        width: 100%; }
  .budgets .budgets-paper {
    margin: 30px 5px;
    padding-bottom: 50px;
    /* min-height: 450px; */
    width: 100%;
    border-radius: 15px; }
    @media (min-width: 1330px) {
      .budgets .budgets-paper {
        margin-left: 0;
        max-width: 1200px;
        width: calc(75% - 10px); } }
  .budgets thead,
  .budgets .more-info {
    height: 0px;
    padding: 0;
    max-width: 700px;
    transition: 0.3s; }
    .budgets thead.open,
    .budgets .more-info.open {
      height: 200px; }
      .budgets thead.open td,
      .budgets thead.open td:first-child,
      .budgets .more-info.open td,
      .budgets .more-info.open td:first-child {
        background: #eee;
        height: 200px; }
        .budgets thead.open td .progress,
        .budgets thead.open td:first-child .progress,
        .budgets .more-info.open td .progress,
        .budgets .more-info.open td:first-child .progress {
          margin-top: 70px; }
    .budgets thead td,
    .budgets thead td:first-child,
    .budgets .more-info td,
    .budgets .more-info td:first-child {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 0px;
      max-width: 700px;
      padding: 0;
      transition: 0.3s; }
      @media (min-width: 950px) {
        .budgets thead td,
        .budgets thead td:first-child,
        .budgets .more-info td,
        .budgets .more-info td:first-child {
          width: 800px; } }
  .budgets .table-budgets .h20 {
    height: 20px; }
  .budgets .table-budgets .h30 {
    height: 30px; }
  .budgets .table-budgets .like-result {
    display: initial;
    margin: 5px; }
    .budgets .table-budgets .like-result > img {
      top: 2px;
      position: relative; }
  .budgets .table-budgets .row-categories,
  .budgets .table-budgets .row-description {
    display: flex;
    align-items: center; }
    .budgets .table-budgets .row-categories > div,
    .budgets .table-budgets .row-description > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
    .budgets .table-budgets .row-categories .tags-list,
    .budgets .table-budgets .row-description .tags-list {
      margin: 5px;
      height: 20px;
      background-color: #7c4dff;
      color: #fff;
      line-height: 1.4em; }
      .budgets .table-budgets .row-categories .tags-list span,
      .budgets .table-budgets .row-description .tags-list span {
        font-family: "Noto Sans";
        font-size: 0.875em; }
  .budgets .table-budgets .category-icon-table {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    color: #ffffff; }
    .budgets .table-budgets .category-icon-table span {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center; }
  .budgets .cards-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: calc(25% - 10px);
    width: 100%; }
    @media (max-width: 1330px) {
      .budgets .cards-info {
        max-width: 100%;
        flex-direction: row;
        justify-content: none; } }
    @media (max-width: 1200px) {
      .budgets .cards-info > div:last-child {
        margin-right: 20px; } }
    @media (max-width: 900px) {
      .budgets .cards-info > div:last-child {
        margin-right: 20px; } }
    .budgets .cards-info .card-infor {
      padding: 2px 5px;
      width: 280px; }
      @media (max-width: 1330px) {
        .budgets .cards-info .card-infor {
          flex: 1; } }
      @media (max-width: 1200px) {
        .budgets .cards-info .card-infor {
          flex: 1 1 calc(50% - 36px); } }
      @media (max-width: 900px) {
        .budgets .cards-info .card-infor {
          flex: 1 1 calc(100% - 36px); } }
      .budgets .cards-info .card-infor .card-conteiner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: none;
        cursor: default;
        overflow: inherit;
        padding: 16px;
        padding-bottom: 24px;
        box-sizing: border-box; }
      .budgets .cards-info .card-infor .card-border-icon {
        font-size: 22px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 3.25rem;
        height: 3.25rem;
        position: relative; }
      .budgets .cards-info .card-infor .card-description {
        margin-right: 0.4rem; }
      .budgets .cards-info .card-infor .card-value {
        font-weight: 600;
        font-size: 1.1em; }
    .budgets .cards-info .card-recipes-month,
    .budgets .cards-info .card-planned-expenses,
    .budgets .cards-info .card-planned-balance,
    .budgets .cards-info .card-planned-economy {
      margin-bottom: 1rem; }
    .budgets .cards-info .progress-card {
      color: #fff; }
    .budgets .cards-info .card-content {
      padding: 0px;
      max-width: calc(100% - 5px - 3.25rem);
      margin-right: 5px;
      display: flex;
      justify-content: space-between;
      text-align: initial;
      flex-direction: column; }
      .budgets .cards-info .card-content h5 {
        margin-top: 0.75rem;
        font-size: 1.4rem; }
    .budgets .cards-info .card-recipes-month .card-border-icon {
      background-color: #4caf50; }
    .budgets .cards-info .card-planned-expenses .card-border-icon {
      background-color: #f44336; }
    .budgets .cards-info .card-planned-balance .card-border-icon {
      background-color: #448aff; }
    .budgets .cards-info .card-planned-economy .card-border-icon {
      background-color: #2e7d32; }

.dialog-warning-archive .dialog-header-monthly,
.dialog-warning-archive .actions-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 26px 12px 26px; }
  .dialog-warning-archive .dialog-header-monthly svg,
  .dialog-warning-archive .actions-buttons svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem; }
    .dialog-warning-archive .dialog-header-monthly svg:hover,
    .dialog-warning-archive .actions-buttons svg:hover {
      transform: scale(1.1); }
  .dialog-warning-archive .dialog-header-monthly button,
  .dialog-warning-archive .actions-buttons button {
    min-width: 90px; }

.dialog-warning-archive .actions-buttons {
  margin-top: 2rem;
  padding-bottom: 20px; }

.dialog-monthly-budget .MuiDialog-paperScrollPaper {
  width: 100%; }
