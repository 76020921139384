.modal-budget {
  overflow: hidden; }
  .modal-budget > div:last-child > div {
    overflow-y: inherit;
    max-width: 750px;
    max-height: 100vh; }

form.form-budget {
  overflow: auto;
  overflow-x: hidden; }
  form.form-budget::-webkit-scrollbar {
    width: 6px;
    background-color: transparent; }
  form.form-budget::-webkit-scrollbar-thumb {
    border-radius: 10px; }
  form.form-budget::-webkit-scrollbar-track {
    border-radius: 10px; }
  form.form-budget > div:first-child {
    width: 500px;
    padding-top: 0;
    overflow: hidden;
    transition: 0.2s; }
    form.form-budget > div:first-child.more-info {
      height: 450px; }
  form.form-budget .bloco-repetir {
    width: 100% !important;
    margin: 0 !important;
    margin-top: -15px !important;
    height: 60px;
    overflow: hidden; }
  form.form-budget .text-block-more .text-field,
  form.form-budget .text-block-more .select-field {
    padding-left: 0; }
  form.form-budget .periodo {
    margin-top: -16px; }
  form.form-budget .repetir-div {
    margin-top: -7px; }
  form.form-budget .categories-label {
    font-size: 0.8em; }
  form.form-budget .chosen-categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
    padding-right: 16px;
    max-height: 102px;
    overflow-y: auto; }
    form.form-budget .chosen-categories .chosen-category {
      color: #ffffff;
      margin: 0px 4px 2px 4px;
      width: auto; }
      form.form-budget .chosen-categories .chosen-category svg {
        color: rgba(255, 255, 255, 0.5); }
  form.form-budget .grid-height {
    height: 70px !important;
    overflow-y: hidden; }
  form.form-budget .grid-height.efetivado label:first-child {
    z-index: 2;
    position: absolute; }
  form.form-budget .grid-height-repetida {
    height: 30px !important;
    overflow-y: hidden;
    text-align: center; }
  form.form-budget .grid-height-repetida-radio {
    height: 70px !important;
    width: 100%;
    overflow-y: hidden; }
  form.form-budget .submit-line {
    display: flex; }
  form.form-budget .text-field,
  form.form-budget .select-field {
    width: calc(100% - 30px); }
    form.form-budget .text-field > div,
    form.form-budget .select-field > div {
      width: 100%; }
  form.form-budget .datepicker-field {
    display: flex; }
    form.form-budget .datepicker-field > div {
      width: 100%; }
      form.form-budget .datepicker-field > div > div {
        position: absolute;
        left: -34 px;
        margin-left: 0; }
        form.form-budget .datepicker-field > div > div button {
          width: 32px;
          height: 32px; }
          form.form-budget .datepicker-field > div > div button span span {
            font-size: 1em; }
    form.form-budget .datepicker-field label {
      display: flex;
      align-items: center; }
      form.form-budget .datepicker-field label svg {
        margin-right: 5px; }
  form.form-budget .form-checkbox svg {
    color: #f44336; }
  form.form-budget .form-icon {
    font-size: 1.4em;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-top: 18px;
    color: inherit;
    opacity: 0.54; }
    form.form-budget .form-icon.mdi-tag {
      padding-top: 8px; }
    form.form-budget .form-icon.mdi-calculator {
      cursor: pointer;
      transition: 0.1s; }
      form.form-budget .form-icon.mdi-calculator:hover {
        opacity: 1; }
  form.form-budget .form-icon-category {
    position: relative;
    line-height: 1.4;
    font-size: 15px;
    color: #fff;
    vertical-align: middle; }
  form.form-budget .form-icon-account {
    position: relative;
    line-height: 1.4;
    font-size: 15px;
    color: #fff;
    vertical-align: middle; }
  form.form-budget .more-information {
    font-size: 0.8em;
    font-style: italic;
    font-weight: normal;
    cursor: pointer;
    text-align: center;
    margin-top: 15px; }
  form.form-budget .text-center {
    text-align: center; }
  form.form-budget .block-more-info {
    margin: 0 !important; }
  form.form-budget .grid-overflow {
    overflow: hidden; }
  form.form-budget .align-check-anexar {
    color: black !important; }
  form.form-budget .input-anexo {
    display: none; }
  form.form-budget .icon-anexar {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.54); }
  form.form-budget .label-anexar {
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px; }
    form.form-budget .label-anexar > span:last-of-type {
      left: -5px;
      position: relative; }
  form.form-budget .icon-render {
    margin-bottom: 5px;
    margin-right: 10px;
    margin-top: 18px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center; }
    form.form-budget .icon-render .form-icon {
      color: #eee;
      font-size: 1.1em;
      padding: 0; }
  form.form-budget .btn-submit-primary {
    margin: 0px 16px; }
  form.form-budget .btn-save-and-continue {
    display: none; }
  form.form-budget .row {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start; }
    form.form-budget .row:last-child {
      margin-bottom: 0; }
    form.form-budget .row .col-2 {
      width: calc(50% - 15px);
      display: flex;
      justify-content: flex-start; }
      form.form-budget .row .col-2:first-child {
        margin-right: 15px; }
      form.form-budget .row .col-2:last-child {
        margin-left: 15px; }
      form.form-budget .row .col-2.date-picker > div {
        width: 100%; }
      form.form-budget .row .col-2.input-value {
        width: 35%;
        margin-right: 0; }
        form.form-budget .row .col-2.input-value label {
          display: flex;
          align-items: center; }
          form.form-budget .row .col-2.input-value label svg {
            margin-right: 5px; }
      form.form-budget .row .col-2.input-description {
        width: 65%;
        margin-right: 0; }
        form.form-budget .row .col-2.input-description label {
          display: flex;
          align-items: center; }
          form.form-budget .row .col-2.input-description label svg {
            margin-right: 5px;
            font-size: 1rem; }
      form.form-budget .row .col-2.checkbox {
        margin-top: 12px;
        width: calc(25% - 15px);
        display: flex;
        justify-content: flex-end; }
        form.form-budget .row .col-2.checkbox label {
          margin-right: 0; }
          form.form-budget .row .col-2.checkbox label span {
            height: 38px;
            display: flex;
            align-items: center; }
            form.form-budget .row .col-2.checkbox label span:first-child {
              width: 38px; }
    form.form-budget .row .col {
      width: 100%;
      display: flex;
      justify-content: flex-start; }
    form.form-budget .row .icon-field.mdi {
      font-size: 1.4em;
      padding-bottom: 5px;
      padding-right: 10px;
      padding-top: 18px;
      color: inherit;
      opacity: 0.5; }
      form.form-budget .row .icon-field.mdi.disabled {
        opacity: 0.5; }
  form.form-budget .paper-blank-suggestions {
    width: 100%;
    margin-left: 0; }
  form.form-budget .input-text-suggestions {
    width: 100%;
    margin-left: 0; }
  form.form-budget .tags-chip-title {
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 5px; }
  form.form-budget .chip-tag {
    margin-left: 5px;
    margin-bottom: 5px; }
  form.form-budget .actions-buttons-custom-create {
    margin: 0 !important; }
    form.form-budget .actions-buttons-custom-create button + button {
      margin-left: 10px; }
  form.form-budget .form-category {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    font-size: 0.8rem; }
    form.form-budget .form-category svg {
      margin-right: 3px;
      font-size: 1rem; }

.dialog-header-custom-budget,
.actions-buttons-custom-create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 26px 16px; }
  .dialog-header-custom-budget svg,
  .actions-buttons-custom-create svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem; }
    .dialog-header-custom-budget svg:hover,
    .actions-buttons-custom-create svg:hover {
      transform: scale(1.1); }
  .dialog-header-custom-budget button,
  .actions-buttons-custom-create button {
    min-width: 90px; }
