/*
This is the Material Design theme for FullCalendar Weekly Agenda view
Creation Date: Aug 19th 2015
Author: Jacky Liang
Version: FullCalendar 2.4.0
Tested Using the Following FC Settings:
    editable: false,
    handleWindowResize: true,
    weekends: false, // Hide weekends
    defaultView: 'agendaWeek', // Only show week view
    header: false, // Hide buttons/titles
    minTime: '07:30:00', // Start time for the calendar
    maxTime: '22:00:00', // End time for the calendar
    columnFormat: {
        week: 'ddd' // Only show day of the week names
    },
    displayEventTime: true,
    allDayText: 'Online/TBD'
Note: This has NOT been tested on Monthly or Daily views.
Colors: Use the following - https://www.google.com/design/spec/style/color.html#color-color-palette
        at the 700 level. An opacity of 0.65 is automatically applied to the
        700 level colors to generate a soft and pleasing look.
        Color were applied to each event using the following code:
        events.push({
            title: 'This is a Material Design event!',
            start: 'someStartDate',
            end: 'someEndDate',
            color: '#C2185B'
        });
*/
/* Remove that awful yellow color and border from today in Schedule */
.fc-today {
  border: none; }

.fc-unthemed td.fc-today {
  background: transparent;
  color: #ffc107; }

.fc-day-grid-event .fc-content {
  text-overflow: ellipsis;
  font-weight: 400; }

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 5px;
  opacity: 0.65;
  left: 5% !important;
  right: 5% !important; }

/* Bolds the name of the event and inherits the font size */
.fc-event {
  font-size: inherit !important;
  font-weight: bold !important;
  border-radius: 25px; }

/* Remove the header border from Schedule */
.fc td,
.fc th {
  border-width: 1px !important;
  padding: 0 !important;
  vertical-align: top !important; }

.fc th {
  font-size: 0.875em; }

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: 0.25 !important; }

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
  font-weight: normal !important; }

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important; }

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important; }

.fc .fc-toolbar > * > :first-child {
  border: 0;
  font-weight: 500;
  background: #fff0; }

.fc-toolbar h2 {
  font-size: 1em !important;
  margin-top: 5px; }

.fc-state-default {
  text-shadow: none;
  box-shadow: none; }

.fc th {
  font-weight: 500;
  padding: 12px 12px 15px !important;
  color: gray; }

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  text-align: center;
  width: 100%;
  padding: 0; }

.fc-event-container {
  font-size: 13px;
  text-align: center; }

.fc-head-container,
.fc-widget-header,
.fc-head,
.fc-day-header {
  border-style: none !important; }

.light.fc-unthemed .fc-content,
.light.fc-unthemed .fc-divider,
.light.fc-unthemed .fc-list-heading td,
.light.fc-unthemed .fc-list-view,
.light.fc-unthemed .fc-popover,
.light.fc-unthemed .fc-row,
.light.fc-unthemed tbody,
.light.fc-unthemed td,
.light.fc-unthemed th,
.light.fc-unthemed thead {
  border-color: #ebedef !important; }

.dark.fc-unthemed .fc-content,
.dark.fc-unthemed .fc-divider,
.dark.fc-unthemed .fc-list-heading td,
.dark.fc-unthemed .fc-list-view,
.dark.fc-unthemed .fc-popover,
.dark.fc-unthemed .fc-row,
.dark.fc-unthemed tbody,
.dark.fc-unthemed td,
.dark.fc-unthemed th,
.dark.fc-unthemed thead {
  border-color: #808080 !important; }

.fc-button,
.fc-icon {
  color: inherit; }

.fc-day {
  padding: 4rem !important; }

.fc-scroller {
  overflow: hidden !important; }
