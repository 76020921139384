.premium-feature-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px; }
  .premium-feature-custom .premium-texts {
    margin-left: 20px;
    position: relative;
    margin-top: 0; }
  .premium-feature-custom .premium-image {
    position: absolute;
    top: -75px;
    left: 125px;
    width: 200px; }
  .premium-feature-custom .premium-title {
    width: 350px;
    height: 100px;
    position: absolute;
    left: 270px;
    bottom: 200px;
    font-size: 23px;
    font-weight: bold; }
    .premium-feature-custom .premium-title span {
      color: #1976d2; }
  .premium-feature-custom .premium-body {
    position: relative;
    top: 135px;
    text-align: center;
    font-size: 25px; }
    .premium-feature-custom .premium-body > div {
      margin-right: 20px;
      font-weight: bold; }
  .premium-feature-custom .premium-button {
    text-align: center;
    position: absolute;
    left: 100px;
    top: 325px; }
    .premium-feature-custom .premium-button button {
      width: 250px;
      font-size: 20px;
      border-radius: 20px;
      background: #7c4dff;
      /* For browsers that do not support gradients */
      background: linear-gradient(to right, #7c4dff, #6515dd);
      text-transform: none; }
