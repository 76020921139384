.rc-slider-tooltip {
  z-index: 9999; }
  .rc-slider-tooltip .rc-slider-tooltip-inner {
    padding: 6px !important;
    border-radius: 15px !important; }

.dialog-create-budget > div:last-child > div {
  max-height: 100%; }

.dialog-create-budget .dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 26px 12px 26px; }
  .dialog-create-budget .dialog-header svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem; }
    .dialog-create-budget .dialog-header svg:hover {
      transform: scale(1.1); }

.rc-slider {
  cursor: pointer; }

form.form-budget-create {
  position: relative;
  z-index: 1;
  padding: 20px; }
  form.form-budget-create > div:first-child {
    min-width: 530px;
    min-height: 345px;
    max-width: 600px;
    padding: initial;
    overflow-y: unset; }
  form.form-budget-create > div:last-child {
    margin: 8px; }
  form.form-budget-create .rc-slider-handle {
    border: solid 2px #fff;
    background-color: #7C4DFF;
    width: 20px;
    height: 20px;
    margin-top: -8px; }
  form.form-budget-create .rc-slider-track {
    background-color: #7C4DFF;
    height: 5px; }
  form.form-budget-create .col-2 {
    width: calc(50% - 15px);
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    form.form-budget-create .col-2:first-child {
      margin-right: 15px; }
    form.form-budget-create .col-2:last-child {
      margin-left: 15px; }
  form.form-budget-create .row-drawer > div > span {
    margin-left: 0 !important; }
  form.form-budget-create .row-budgets .col {
    width: 100%;
    display: block; }
    form.form-budget-create .row-budgets .col .budget-value-total {
      display: grid;
      justify-content: center;
      text-align: center;
      margin: 25px 0;
      width: 100%; }
      form.form-budget-create .row-budgets .col .budget-value-total span + span {
        font-size: 30px !important;
        font-weight: bold; }
        form.form-budget-create .row-budgets .col .budget-value-total span + span .plus-value {
          color: #2e7d32;
          font-size: 20px; }
      form.form-budget-create .row-budgets .col .budget-value-total > span:last-of-type {
        font-size: 15px;
        color: #828282;
        font-weight: bold;
        font-family: "Maven Pro"; }
    form.form-budget-create .row-budgets .col .content-categories {
      overflow: auto;
      position: relative;
      flex: 1 1 auto;
      max-height: 250px;
      font-family: "Maven Pro"; }
    form.form-budget-create .row-budgets .col .item-category {
      display: flex;
      font-size: 18px;
      font-weight: 500;
      align-items: center;
      margin-bottom: 15px;
      display: list-item;
      font-family: "Maven Pro"; }
      form.form-budget-create .row-budgets .col .item-category > span {
        font-family: "Maven Pro"; }
      form.form-budget-create .row-budgets .col .item-category.remaining-budget {
        display: block;
        margin-top: 10px;
        margin-bottom: 0;
        width: 100%; }
      form.form-budget-create .row-budgets .col .item-category .item-description > span {
        font-family: "Maven Pro"; }
      form.form-budget-create .row-budgets .col .item-category .item-description {
        display: inline-grid; }
        form.form-budget-create .row-budgets .col .item-category .item-description .amount-spent-by-category {
          font-size: 12px;
          color: #777777; }
    form.form-budget-create .row-budgets .col .item-sub-category {
      font-size: 20px;
      font-weight: 500;
      align-items: center;
      margin-left: 30px;
      margin-bottom: 23px; }
      form.form-budget-create .row-budgets .col .item-sub-category > span {
        font-family: "Maven Pro"; }
      form.form-budget-create .row-budgets .col .item-sub-category > span:last-of-type {
        margin-left: 10px; }
      form.form-budget-create .row-budgets .col .item-sub-category .item-description > span {
        font-family: "Maven Pro"; }
      form.form-budget-create .row-budgets .col .item-sub-category .item-description {
        display: inline-grid;
        margin-left: 5px; }
        form.form-budget-create .row-budgets .col .item-sub-category .item-description .amount-spent-by-category {
          font-size: 12px;
          color: #777777; }
    form.form-budget-create .row-budgets .col .icon-category-avatar {
      width: 40px;
      height: 40px;
      display: inline-flex;
      position: relative;
      font-size: 1.25rem;
      flex-shrink: 0;
      align-items: center;
      user-select: none;
      border-radius: 50%;
      justify-content: center;
      margin-right: 10px; }
      form.form-budget-create .row-budgets .col .icon-category-avatar span {
        color: #fff; }
      form.form-budget-create .row-budgets .col .icon-category-avatar .remaining-budget {
        color: #000;
        opacity: 0.5; }
    form.form-budget-create .row-budgets .col .icon-category-avatar-sub {
      width: 25px;
      height: 25px;
      display: inline-flex;
      position: relative;
      font-size: 1.25rem;
      flex-shrink: 0;
      align-items: center;
      user-select: none;
      border-radius: 50%;
      justify-content: center;
      top: 5px; }
      form.form-budget-create .row-budgets .col .icon-category-avatar-sub span {
        color: #fff; }
    form.form-budget-create .row-budgets .col .value-budget {
      float: right;
      margin-right: 5px; }
    form.form-budget-create .row-budgets .col .value-remaining-budget {
      float: right;
      margin-right: 5px;
      margin-top: 7px; }
  form.form-budget-create button {
    min-width: 140px;
    margin: 0; }
  form.form-budget-create .actions-buttons {
    margin: 0 !important; }
    form.form-budget-create .actions-buttons button + button {
      margin-left: 10px; }
  form.form-budget-create .row {
    width: 100%;
    margin-bottom: 20px;
    justify-content: flex-start; }
    form.form-budget-create .row.select-auto {
      display: block; }
      form.form-budget-create .row.select-auto .col > .row > .col {
        display: flex; }
    form.form-budget-create .row:last-child {
      margin-bottom: 0; }
  form.form-budget-create .form-icon {
    font-size: 2.3em;
    padding-right: 10px;
    padding-top: 5px;
    color: inherit;
    opacity: 0.54; }
    form.form-budget-create .form-icon.mdi-tag {
      padding-top: 8px; }
    form.form-budget-create .form-icon.mdi-calculator {
      cursor: pointer;
      transition: 0.1s; }
      form.form-budget-create .form-icon.mdi-calculator:hover {
        opacity: 1; }
  form.form-budget-create .alert-messages {
    display: flex;
    color: #e57373;
    font-size: 15px;
    flex-wrap: wrap; }
    form.form-budget-create .alert-messages span {
      margin-bottom: 10px; }
  form.form-budget-create .select-field {
    cursor: pointer;
    width: 100%; }
    form.form-budget-create .select-field > div {
      width: 100%; }
    form.form-budget-create .select-field ul {
      max-height: 250px; }
    form.form-budget-create .select-field .select-type {
      position: relative;
      margin-top: 0;
      padding: 0;
      width: 100%; }
  form.form-budget-create .text-field > div {
    width: 100%; }
  form.form-budget-create .icon-field.mdi {
    font-size: 1.4em;
    padding: 0 !important;
    margin: 0 10px 0 0 !important;
    color: inherit; }
  form.form-budget-create .icon-field.icon-category {
    margin-bottom: 5px;
    margin-right: 10px;
    margin-top: 12px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
    form.form-budget-create .icon-field.icon-category:before {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      font-size: 18px;
      color: #ffffff; }

.form-category {
  align-items: center;
  display: flex;
  margin-bottom: 10px; }
  .form-category svg {
    margin-right: 8px; }

.row-drawer .Select-multi-value-wrapper {
  margin: 10px 0px 3px; }

.row-drawer .Select-placeholder {
  margin: 10px 0px 3px; }

.row-drawer .MuiInputBase-input {
  padding: 10px 0px 14px; }

.alert-form-group {
  margin: 10px 0px; }
  .alert-form-group .MuiTypography-body1 {
    margin-right: 10px; }

.light ::-webkit-scrollbar-thumb {
  background-color: #9e9e9e; }

.dark ::-webkit-scrollbar-thumb {
  background-color: #757575; }

.form-budget-create {
  overflow: auto; }
  .form-budget-create::-webkit-scrollbar {
    width: 6px;
    background-color: transparent; }
  .form-budget-create::-webkit-scrollbar-thumb {
    /* #515151 */
    border-radius: 10px; }
  .form-budget-create::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5; */
    border-radius: 10px; }
  .form-budget-create .edit,
  .form-budget-create .content {
    justify-content: space-between;
    display: flex;
    align-items: center; }
    .form-budget-create .edit label,
    .form-budget-create .content label {
      display: flex;
      align-items: center; }
      .form-budget-create .edit label svg,
      .form-budget-create .content label svg {
        margin-right: 5px; }
