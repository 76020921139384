.modal-birthday-user div:last-child {
  border-radius: 20px;
  overflow: inherit; }

.modal-birthday-user .content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 300px; }
  .modal-birthday-user .content .birthday-image {
    position: absolute;
    top: -85px;
    left: 50px;
    width: 280px; }
  .modal-birthday-user .content .birthday-body {
    width: 90%;
    margin-top: 115px;
    text-align: center; }
    .modal-birthday-user .content .birthday-body .title {
      width: 80%;
      margin: auto; }
    .modal-birthday-user .content .birthday-body h2 {
      font-weight: 500;
      font-size: 1.4em; }
    .modal-birthday-user .content .birthday-body h3 {
      font-weight: 400;
      font-size: 1em; }
