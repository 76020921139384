.pie-chart {
  position: relative;
  transition: 0.2s;
  width: 100%; }
  .pie-chart svg {
    margin: auto;
    display: flex; }
  .pie-chart path {
    cursor: pointer;
    transition: 0.3s; }
    .pie-chart path:hover {
      border: 1px solid; }
  .pie-chart .circle-legend {
    transition: 0.3s; }
  .pie-chart .text-legend {
    transition: 0.3s;
    background: transparent; }
    .pie-chart .text-legend p {
      font-weight: 500;
      margin: 4px;
      color: inherit; }
      .pie-chart .text-legend p.legend-percent {
        margin-top: 20px;
        font-size: 1.3em;
        font-weight: 600; }
      .pie-chart .text-legend p.legend-description {
        font-size: 0.875em; }
      .pie-chart .text-legend p.legend-value {
        font-size: 0.8em; }
      .pie-chart .text-legend p.legend-total-title {
        font-size: 0.875em;
        text-align: center; }
      .pie-chart .text-legend p.legend-total-value {
        margin-top: 25px;
        font-size: 1.3em;
        text-align: center; }
  .pie-chart .chart-tooltip {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    color: #eee;
    display: none;
    left: 130px;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 95px;
    width: 100px;
    height: 40px;
    z-index: 10; }
    .pie-chart .chart-tooltip .label, .pie-chart .chart-tooltip .percent {
      color: white;
      font-size: 0.875em;
      font-weight: 500; }
  .pie-chart .breadcrumbs-chart {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 30px;
    margin: 1rem 0; }
    .pie-chart .breadcrumbs-chart h6:first-child {
      cursor: pointer;
      margin-right: 15px;
      border-bottom: 1px solid #c1c1c1;
      transition: 0.2s; }
      .pie-chart .breadcrumbs-chart h6:first-child:hover {
        border-color: currentColor;
        opacity: 1; }
    .pie-chart .breadcrumbs-chart h6:last-child {
      margin-left: 15px; }

@-moz-document url-prefix() {
  .pie-chart {
    width: 50%;
    margin: 0 auto; } }
