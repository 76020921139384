.success-create-monthly-budget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: inherit !important;
  width: 430px !important;
  font-family: "Maven Pro"; }
  .success-create-monthly-budget .content-figure {
    justify-content: center;
    display: flex; }
    .success-create-monthly-budget .content-figure img {
      width: 80%; }
  .success-create-monthly-budget .content-body {
    text-align: center; }
    .success-create-monthly-budget .content-body h4 {
      margin: 20px auto;
      font-size: 1.6rem;
      width: 80%; }
    .success-create-monthly-budget .content-body h6 {
      width: 90%;
      text-align: initial;
      margin: auto;
      line-height: 1; }
    .success-create-monthly-budget .content-body div {
      color: inherit; }
    .success-create-monthly-budget .content-body-title {
      font-size: 31px;
      font-weight: bold;
      margin: 20px 0; }
    .success-create-monthly-budget .content-body-content {
      font-size: 18px;
      font-weight: 500; }
      .success-create-monthly-budget .content-body-content:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0; }
        .success-create-monthly-budget .content-body-content:last-child img {
          width: 40px;
          margin-left: 10px; }
  .success-create-monthly-budget .content-approve {
    margin: 30px 0; }
    .success-create-monthly-budget .content-approve button {
      width: 160px; }

.actions-buttons-success-create-monthly-budget {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 26px 26px 0 0; }
  .actions-buttons-success-create-monthly-budget svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem; }
    .actions-buttons-success-create-monthly-budget svg:hover {
      transform: scale(1.1); }
