.mdi-1:before {
  content: "\F5F2"; }

.mdi-2:before {
  content: "\F10B"; }

.mdi-3:before {
  content: "\F53F"; }

.mdi-4:before {
  content: "\F072"; }

.mdi-5:before {
  content: "\F092"; }

.mdi-6:before {
  content: "\F5F6"; }

.mdi-7:before {
  content: "\F2DC"; }

.mdi-8:before {
  content: "\F5DA"; }

.mdi-9:before {
  content: "\F114"; }

.mdi-10:before {
  content: "\F2A1"; }

.mdi-11:before {
  content: "\F535"; }

.mdi-12:before {
  content: "\F539"; }

.mdi-13:before {
  content: "\F1D8"; }

.mdi-14:before {
  content: "\F01D"; }

.mdi-15:before {
  content: "\F68E"; }

.mdi-16:before {
  content: "\F070"; }

.mdi-17:before {
  content: "\F076"; }

.mdi-18:before {
  content: "\F0A3"; }

.mdi-19:before {
  content: "\F0B9"; }

.mdi-20:before {
  content: "\F0BA"; }

.mdi-21:before {
  content: "\F0D6"; }

.mdi-22:before {
  content: "\F0E2"; }

.mdi-23:before {
  content: "\F0E7"; }

.mdi-24:before {
  content: "\F0EB"; }

.mdi-25:before {
  content: "\F0EC"; }

.mdi-26:before {
  content: "\F0F6"; }

.mdi-27:before {
  content: "\F0FC"; }

.mdi-28:before {
  content: "\F100"; }

.mdi-29:before {
  content: "\F10A"; }

.mdi-30:before {
  content: "\F110"; }

.mdi-31:before {
  content: "\F117"; }

.mdi-32:before {
  content: "\F11B"; }

.mdi-33:before {
  content: "\F120"; }

.mdi-34:before {
  content: "\F127"; }

.mdi-35:before {
  content: "\F144"; }

.mdi-36:before {
  content: "\F146"; }

.mdi-37:before {
  content: "\F14D"; }

.mdi-38:before {
  content: "\F176"; }

.mdi-39:before {
  content: "\F188"; }

.mdi-40:before {
  content: "\F19B"; }

.mdi-41:before {
  content: "\F1C4"; }

.mdi-42:before {
  content: "\F1C8"; }

.mdi-43:before {
  content: "\F1E0"; }

.mdi-44:before {
  content: "\F1E6"; }

.mdi-45:before {
  content: "\F1EE"; }

.mdi-46:before {
  content: "\F1F2"; }

.mdi-47:before {
  content: "\F1F3"; }

.mdi-48:before {
  content: "\F1F7"; }

.mdi-49:before {
  content: "\F215"; }

.mdi-50:before {
  content: "\F230"; }

.mdi-51:before {
  content: "\F240"; }

.mdi-52:before {
  content: "\F24A"; }

.mdi-53:before {
  content: "\F27C"; }

.mdi-54:before {
  content: "\F290"; }

.mdi-55:before {
  content: "\F297"; }

.mdi-56:before {
  content: "\F298"; }

.mdi-57:before {
  content: "\F29B"; }

.mdi-58:before {
  content: "\F2C4"; }

.mdi-59:before {
  content: "\F2C8"; }

.mdi-60:before {
  content: "\F2D1"; }

.mdi-61:before {
  content: "\F2E0"; }

.mdi-62:before {
  content: "\F2E3"; }

.mdi-63:before {
  content: "\F2E8"; }

.mdi-64:before {
  content: "\F5CF"; }

.mdi-65:before {
  content: "\F2F3"; }

.mdi-66:before {
  content: "\F306"; }

.mdi-67:before {
  content: "\F6B4"; }

.mdi-68:before {
  content: "\F64F"; }

.mdi-69:before {
  content: "\F335"; }

.mdi-70:before {
  content: "\F34D"; }

.mdi-71:before {
  content: "\F356"; }

.mdi-72:before {
  content: "\F370"; }

.mdi-73:before {
  content: "\F37C"; }

.mdi-74:before {
  content: "\F387"; }

.mdi-75:before {
  content: "\F395"; }

.mdi-76:before {
  content: "\F39E"; }

.mdi-77:before {
  content: "\F39A"; }

.mdi-78:before {
  content: "\F602"; }

.mdi-79:before {
  content: "\F402"; }

.mdi-80:before {
  content: "\F403"; }

.mdi-81:before {
  content: "\F405"; }

.mdi-82:before {
  content: "\F41D"; }

.mdi-83:before {
  content: "\F428"; }

.mdi-84:before {
  content: "\F46E"; }

.mdi-85:before {
  content: "\F473"; }

.mdi-86:before {
  content: "\F474"; }

.mdi-87:before {
  content: "\F49C"; }

.mdi-88:before {
  content: "\F4A6"; }

.mdi-89:before {
  content: "\F4B8"; }

.mdi-90:before {
  content: "\F4C7"; }

.mdi-91:before {
  content: "\F4D3"; }

.mdi-92:before {
  content: "\F4D9"; }

.mdi-93:before {
  content: "\F4DC"; }

.mdi-94:before {
  content: "\F4DE"; }

.mdi-95:before {
  content: "\F4DF"; }

.mdi-96:before {
  content: "\F4FF"; }

.mdi-97:before {
  content: "\F513"; }

.mdi-98:before {
  content: "\F519"; }

.mdi-99:before {
  content: "\F529"; }

.mdi-100:before {
  content: "\F53B"; }

.mdi-101:before {
  content: "\F531"; }

.mdi-102:before {
  content: "\F565"; }

.mdi-103:before {
  content: "\F57C"; }

.mdi-104:before {
  content: "\F584"; }

.mdi-105:before {
  content: "\F589"; }

.mdi-106:before {
  content: "\F5B7"; }

.mdi-107:before {
  content: "\F5B5"; }

.mdi-108:before {
  content: "\F59F"; }

body {
  background: #edf3fb;
  font-family: "Noto Sans" !important; }
  body .loading-app.hidden-opacity {
    opacity: 0; }
  body .loading-app.hidden {
    display: none; }
  body button {
    outline: none; }

div[role="dialog"] .btn-submit-primary {
  border-radius: 30px; }

div[role="dialog"] h2 {
  font-family: "Maven Pro"; }

form.form-credit-card button#btn-save-and-continue:disabled, form.form-account button#btn-save-and-continue:disabled, form.form-budget button#btn-save-and-continue:disabled, form.form-category button#btn-save-and-continue:disabled, form.form-subcategory button#btn-save-and-continue:disabled {
  background-color: transparent;
  color: rgba(124, 77, 255, 0.5); }

form.form-credit-card button:disabled, form.form-account button:disabled, form.form-budget button:disabled, form.form-category button:disabled, form.form-subcategory button:disabled {
  background-color: rgba(124, 77, 255, 0.5);
  color: rgba(255, 255, 255, 0.3); }

.App {
  text-align: center; }
  .App table,
  .App input,
  .App label,
  .App button span,
  .App h3 {
    font-family: "Noto Sans"; }
  .App > .snackbar-notification > div {
    background-color: #7c4dff;
    cursor: pointer;
    transition: background-color 0.5s !important; }
    .App > .snackbar-notification > div:hover {
      background-color: #6515dd; }
  .App table td,
  .App table th {
    padding: 10px; }
    .App table td:first-child,
    .App table th:first-child {
      padding-left: 30px; }
    .App table td.action-buttons,
    .App table th.action-buttons {
      padding-left: 10px;
      padding-right: 30px; }
      .App table td.action-buttons button,
      .App table th.action-buttons button {
        width: 30px;
        height: 30px;
        padding: 0; }
        .App table td.action-buttons button.icon-favorite,
        .App table th.action-buttons button.icon-favorite {
          color: #fb3; }
          .App table td.action-buttons button.icon-favorite:hover svg,
          .App table th.action-buttons button.icon-favorite:hover svg {
            color: #fb3; }
          .App table td.action-buttons button.icon-favorite svg,
          .App table th.action-buttons button.icon-favorite svg {
            width: 20px;
            height: 20px; }
      .App table td.action-buttons svg,
      .App table th.action-buttons svg {
        width: 18px;
        height: 18px; }
  .App button span .tooltip-btn-icon {
    font: normal normal normal 24px/1 "Material Design Icons"; }
  .App h2 {
    font-family: "Maven Pro"; }

.dialog-warning-archive h2 {
  font-family: "Maven Pro"; }

div[role="dialog"] table,
div[role="dialog"] ul li,
div[role="dialog"] label,
div[role="dialog"] .select-field div[role="button"] {
  font-family: "Noto Sans"; }

div[role="dialog"] h2 span {
  font-family: "Maven Pro"; }

.premium-modal div:last-of-type {
  border-radius: 20px;
  overflow: inherit; }

.premium-modal .premium-modal-content {
  max-width: 710px;
  height: 300px;
  padding: 24px; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #7c4dff;
  height: 80px;
  padding: 20px;
  color: white; }

.App-intro {
  font-size: large; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.container-main {
  position: relative;
  transition: 0.5s; }
  .container-main.extend-menu-active {
    flex: 1; }
  .container-main table {
    margin-bottom: 30px; }
  .container-main .tabs > div > div > span {
    background-color: #ffffff; }
  .container-main header {
    padding-left: 20px;
    box-sizing: border-box;
    position: absolute;
    z-index: 5; }
    .container-main header > div {
      min-height: 56px;
      height: 56px; }
    .container-main header .actions-toolbar-btn {
      position: fixed;
      right: 25px; }
      .container-main header .actions-toolbar-btn button {
        color: #ffffff; }
    .container-main header h2,
    .container-main header h2 span {
      font-weight: 400; }
  .container-main .toolbar-actions h2,
  .container-main .toolbar-options h2 {
    font-family: "Maven Pro"; }
  .container-main .toolbar-actions .change-tab,
  .container-main .toolbar-options .change-tab {
    color: #ffffff; }
  .container-main .toolbar-actions.dropdown-tabs,
  .container-main .toolbar-options.dropdown-tabs {
    padding-left: 10px; }
    .container-main .toolbar-actions.dropdown-tabs .change-tab,
    .container-main .toolbar-options.dropdown-tabs .change-tab {
      color: inherit;
      padding-left: 10px; }
  .container-main .toolbar-actions button:disabled .tooltip-btn-icon .mdi,
  .container-main .toolbar-options button:disabled .tooltip-btn-icon .mdi {
    color: #9e9e9e; }
  .container-main .toolbar-actions .tooltip-btn-icon .mdi,
  .container-main .toolbar-options .tooltip-btn-icon .mdi {
    color: #616161;
    font-size: 0.8em; }
  .container-main .toolbar-actions .tooltip-mdi.mdi,
  .container-main .toolbar-options .tooltip-mdi.mdi {
    color: #616161;
    font-size: 0.8em; }
  .container-main .toolbar-actions.toolbar-search,
  .container-main .toolbar-options.toolbar-search {
    padding: 0;
    padding-top: 10px; }
    .container-main .toolbar-actions.toolbar-search > div,
    .container-main .toolbar-options.toolbar-search > div {
      width: 100%;
      padding-left: 12px; }
      .container-main .toolbar-actions.toolbar-search > div .mdi,
      .container-main .toolbar-options.toolbar-search > div .mdi {
        color: #9e9e9e;
        margin-bottom: 5px; }
  .container-main .content {
    padding-top: 10px; }
  .container-main .filter-toolbar {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    padding-bottom: 10px;
    padding-right: 0; }
    .container-main .filter-toolbar .filters-values {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: calc(100% - 100px); }
      .container-main .filter-toolbar .filters-values > span {
        font-size: 0.875em;
        margin-right: 5px;
        padding-top: 6px; }
    .container-main .filter-toolbar .filter-actions {
      width: 100px; }

.modal-close {
  float: right;
  position: relative;
  font-size: 18px;
  cursor: pointer;
  transition: 0.4s; }
  .modal-close:hover {
    transform: scale(1.1); }

#long-menu ul li,
#simple-menu ul li {
  padding: 5px 10px;
  height: 40px;
  font-size: 0.95em;
  font-family: "Noto Sans"; }
  #long-menu ul li .mdi.mdi-file-excel:before,
  #simple-menu ul li .mdi.mdi-file-excel:before {
    color: inherit; }

#custom-menu ul li {
  padding: 0px 35px;
  height: 40px;
  font-size: 0.95em; }

form .btn-send-primary {
  background: #7c4dff;
  font-weight: 400; }

ul li.select-items-menu {
  padding: 5px 10px;
  height: 40px;
  font-size: 0.95em; }

.text-field,
.select-field {
  display: flex;
  justify-content: flex-start; }
  .text-field .mdi,
  .select-field .mdi {
    font-size: 1.4em;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-top: 18px;
    color: rgba(0, 0, 0, 0.54); }

.btn-submit .loading-send {
  color: #fff; }

.switch-toolbar label span:last-child {
  font-weight: 500;
  font-size: 0.875em;
  font-family: "Noto Sans"; }

.snackbar-mbs > div {
  display: flex;
  margin-bottom: 5px;
  border-radius: 15px;
  background-color: #7c4dff;
  color: #fff; }
  .snackbar-mbs > div > div:first-child {
    width: 100%;
    padding: 0; }
  .snackbar-mbs > div img {
    width: 30px;
    height: 30px;
    margin-left: 4px;
    margin-right: 4px; }

.container-snackbar .snackbar-loading {
  color: #fff; }

.loading > div[role="alertdialog"] {
  border-radius: 15px; }

.snackbar-success > div {
  background: #009688;
  border-radius: 15px;
  color: #fff; }

.snackbar-error > div {
  background: #ef5350;
  border-radius: 15px;
  color: #fff; }

.snackbar-alert {
  margin-bottom: 40px; }

.snackbar-alert > div {
  background: orange;
  border-radius: 15px; }

.drawer-filter .filter-title {
  padding: 18px;
  display: flex;
  justify-content: center; }
  .drawer-filter .filter-title span {
    text-align: center;
    font-size: 1.2em;
    font-weight: 500; }

.drawer-filter .filter-content {
  padding: 16px;
  position: relative; }
  .drawer-filter .filter-content .control-select {
    width: 100%; }
  .drawer-filter .filter-content.filter-input-sugestions > div {
    z-index: 10;
    width: 100%; }
    .drawer-filter .filter-content.filter-input-sugestions > div > div {
      width: 100%; }
      .drawer-filter .filter-content.filter-input-sugestions > div > div.paper-blank-suggestions {
        width: calc(100% - 8px);
        position: absolute;
        z-index: 100; }
        .drawer-filter .filter-content.filter-input-sugestions > div > div.paper-blank-suggestions .item-suggestion {
          font-family: "Noto Sans"; }
  .drawer-filter .filter-content.filter-input-sugestions input {
    font-family: "Noto Sans"; }
  .drawer-filter .filter-content.filter-input-sugestions ul {
    max-height: 400px;
    overflow-y: auto; }
    .drawer-filter .filter-content.filter-input-sugestions ul li > div {
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: "Noto Sans"; }

.drawer-filter .filter-actions {
  padding: 16px;
  display: flex;
  justify-content: flex-end; }
  .drawer-filter .filter-actions button {
    margin-left: 16px; }

.card-dashboard .card-title {
  width: 100%;
  padding: 12px 5px 20px 21px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .card-dashboard .card-title button {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 32px;
    min-width: 100px; }
    .card-dashboard .card-title button span {
      font-weight: 500;
      font-size: 1em; }

body .uv-icon {
  z-index: 20; }
  body .uv-icon.user-online {
    opacity: 1; }
  body .uv-icon.uv-bottom-left {
    left: 24px; }
    body .uv-icon.uv-bottom-left svg {
      transform: rotateY(180deg); }

ul[role="menu"] li.menu-item-title {
  background-color: #7c4dff; }
  ul[role="menu"] li.menu-item-title span {
    font-weight: 500;
    color: #fff; }
  ul[role="menu"] li.menu-item-title:hover {
    background: #7c4dff; }

.cards-info .card-info {
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: none;
  cursor: default;
  overflow: inherit; }
  .cards-info .card-info .card-border-icon {
    font-size: 22px;
    margin-left: -14px;
    color: #fff;
    height: 60px;
    width: 60px;
    position: absolute;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cards-info .card-info > div:last-child {
    display: flex;
    margin-left: 20px;
    width: calc(100% - 40px);
    height: 70px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    padding-left: 40px;
    padding-right: 16px;
    border-radius: 15px; }
    .cards-info .card-info > div:last-child span {
      text-align: right; }
  .cards-info .card-info .card-description {
    color: #757575;
    font-size: 0.8em;
    text-transform: uppercase;
    text-align: start; }
  .cards-info .card-info .card-value {
    font-weight: 600;
    font-size: 1.1em; }

.chart-tooltip {
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 26px;
  z-index: 9999;
  text-align: center; }

.chart-axis line {
  opacity: 0.1; }

.chart-axis text {
  color: #757575;
  font-size: 0.725rem; }

.input-description-light:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important; }
  .input-description-light:-webkit-autofill:hover, .input-description-light:-webkit-autofill:focus, .input-description-light:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important; }

.input-description-dark:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #333 inset !important;
  -webkit-text-fill-color: #fff !important; }
  .input-description-dark:-webkit-autofill:hover, .input-description-dark:-webkit-autofill:focus, .input-description-dark:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #333 inset !important;
    -webkit-text-fill-color: #fff !important; }

@keyframes SlideIn {
  from {
    width: 3rem;
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes SlideOut {
  from {
    width: 28.125rem; }
  to {
    width: 16px;
    padding-right: 0;
    opacity: 0;
    border-bottom: none; } }

.mfa-character-input {
  border-radius: 10px; }
