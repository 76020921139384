.dialog-delete-custom-budget .MuiDialog-paperScrollPaper {
  width: 100%; }

.dialog-header-custom-budgets,
.actions-buttons-custom-budgets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 26px 12px 26px; }
  .dialog-header-custom-budgets svg,
  .actions-buttons-custom-budgets svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem; }
    .dialog-header-custom-budgets svg:hover,
    .actions-buttons-custom-budgets svg:hover {
      transform: scale(1.1); }
  .dialog-header-custom-budgets button,
  .actions-buttons-custom-budgets button {
    min-width: 90px; }

.actions-buttons-custom-budgets {
  margin-top: 2rem;
  padding-bottom: 20px; }
