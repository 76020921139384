.content-import-excel {
  width: 100%;
  padding-left: 40px;
  padding-right: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box; }

.import-data-excel {
  max-width: 1024px;
  width: 100%; }
  .import-data-excel.import-data-paper {
    margin: 30px;
    margin-top: 30px;
    padding: 20px;
    border-radius: 15px;
    max-width: 1024px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .import-data-excel.import-data-paper.import-excel-content.tabs-import-data {
      margin-top: 20px;
      border-bottom: 1px solid #e1e1e1; }
  .import-data-excel .empty-import {
    display: flex;
    flex-direction: column; }
    .import-data-excel .empty-import .mdi {
      font-size: 7em;
      margin-top: 40px;
      color: #9e9e9e; }
    .import-data-excel .empty-import h2 {
      margin: 100px 0;
      color: #9e9e9e; }
  .import-data-excel .transactions-excel-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px; }
    .import-data-excel .transactions-excel-title .Mui-checked {
      margin-top: -32px; }
    .import-data-excel .transactions-excel-title span.transaction-title {
      margin-top: -33px;
      font-size: 1.2em;
      font-weight: 500; }
    .import-data-excel .transactions-excel-title .content-btn-actions {
      margin-left: auto;
      width: auto; }
  .import-data-excel .import-excel-content a {
    color: #7c4dff;
    text-decoration: none;
    text-align: left; }
    .import-data-excel .import-excel-content a span {
      font-size: 1em; }
      .import-data-excel .import-excel-content a span.mdi {
        margin-right: 4px; }
  .import-data-excel .import-excel-content.tabs-import-data {
    margin-top: 20px;
    border-bottom: 1px solid #e1e1e1; }
  .import-data-excel .import-excel-content .row-title {
    margin-bottom: 25px; }
  .import-data-excel .import-excel-content .row-know-how,
  .import-data-excel .import-excel-content .row-title {
    display: flex;
    width: 100%;
    justify-content: flex-start; }
  .import-data-excel .import-excel-content .row {
    display: flex;
    margin-top: 30px;
    justify-content: flex-start; }
    .import-data-excel .import-excel-content .row.row-form {
      align-items: flex-end; }
  .import-data-excel .import-excel-content .row-action {
    display: flex;
    justify-content: flex-end; }
    .import-data-excel .import-excel-content .row-action button {
      background-color: #7c4dff;
      background: linear-gradient(to right, #7c4dff, #6515dd);
      border-radius: 30px;
      color: #ffffff;
      margin-left: 50px;
      width: 150px;
      height: 32px;
      padding: 0; }
      .import-data-excel .import-excel-content .row-action button.btn-send:disabled {
        background-color: #e1e1e1; }
      .import-data-excel .import-excel-content .row-action button span {
        width: 100%;
        height: 32px; }
  .import-data-excel .import-excel-content .form-control {
    width: 100%; }
    .import-data-excel .import-excel-content .form-control > div {
      text-align: left;
      width: 100%; }
  .import-data-excel .import-excel-content .form-import-file {
    height: 32px;
    background: transparent;
    color: #7c4dff;
    border: 2px solid;
    padding: 0;
    margin-left: 50px;
    width: 200px;
    border-radius: 30px; }
    .import-data-excel .import-excel-content .form-import-file span {
      height: 32px; }
    .import-data-excel .import-excel-content .form-import-file label {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.875em; }
    .import-data-excel .import-excel-content .form-import-file input {
      opacity: 0;
      position: absolute;
      z-index: -1; }
  .import-data-excel .import-excel-content .file-name-ofx {
    margin-left: 20px;
    height: 36px;
    display: flex;
    align-items: center; }
  .import-data-excel .transactions-bank-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 40px;
    margin-bottom: 10px; }
    .import-data-excel .transactions-bank-title span.transaction-title {
      font-size: 1.2em;
      font-weight: 500; }
    .import-data-excel .transactions-bank-title .content-btn-actions {
      margin-left: auto; }
      .import-data-excel .transactions-bank-title .content-btn-actions button {
        margin-right: -10px; }
  .import-data-excel .import-data-transaction-card:last-child {
    margin-bottom: 70px; }
  .import-data-excel .import-data-transaction-card .transaction-card-register {
    width: 100%;
    padding: 20px;
    padding-left: 0;
    box-sizing: border-box;
    margin: 20px 0px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: flex-start;
    border-radius: 15px; }
    .import-data-excel .import-data-transaction-card .transaction-card-register.light {
      background-color: #fff; }
    .import-data-excel .import-data-transaction-card .transaction-card-register.dark {
      background-color: #333; }
    .import-data-excel .import-data-transaction-card .transaction-card-register .col-data {
      display: flex;
      flex-direction: column;
      width: calc(100% - 60px);
      margin-left: 12px; }
    .import-data-excel .import-data-transaction-card .transaction-card-register .row:last-child {
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0; }
      .import-data-excel .import-data-transaction-card .transaction-card-register .row:last-child .col-likely-duplication {
        margin-right: auto;
        display: flex;
        align-items: center; }
        .import-data-excel .import-data-transaction-card .transaction-card-register .row:last-child .col-likely-duplication span {
          color: #ff8f00; }
        .import-data-excel .import-data-transaction-card .transaction-card-register .row:last-child .col-likely-duplication .mdi {
          font-size: 1.4em;
          font-weight: 500; }
    .import-data-excel .import-data-transaction-card .transaction-card-register .row .messages {
      margin-right: 60px; }
    .import-data-excel .import-data-transaction-card .transaction-card-register .row .text-field {
      width: 100%; }
    .import-data-excel .import-data-transaction-card .transaction-card-register .row .btn-add {
      background-color: #7c4dff;
      background: linear-gradient(to right, #7c4dff, #6515dd);
      border-radius: 30px;
      color: #ffffff; }
    .import-data-excel .import-data-transaction-card .transaction-card-register .row .btn-cancel {
      background-color: #919191;
      color: #ffffff;
      border-radius: 30px;
      margin-right: 10px; }
    .import-data-excel .import-data-transaction-card .transaction-card-register .row .select-field {
      width: 100%; }
      .import-data-excel .import-data-transaction-card .transaction-card-register .row .select-field > div {
        width: 100%; }
        .import-data-excel .import-data-transaction-card .transaction-card-register .row .select-field > div > div {
          text-align: left; }
  .import-data-excel .row {
    display: flex;
    margin-bottom: 16px; }
    .import-data-excel .row .col-2,
    .import-data-excel .row .col-small,
    .import-data-excel .row .col-large {
      margin-right: 10px;
      display: flex;
      justify-content: flex-start; }
    .import-data-excel .row .col-2 {
      width: calc(50% - 10px); }
    .import-data-excel .row .col-small {
      width: calc(25% - 10px); }
    .import-data-excel .row .col-large {
      width: calc(75% - 10px); }
  .import-data-excel .info-credit-card-selected {
    display: flex;
    margin-left: 30px;
    margin-top: -33px; }
    .import-data-excel .info-credit-card-selected > div {
      margin-left: 16px; }
      .import-data-excel .info-credit-card-selected > div span:first-child {
        font-weight: 500; }
    .import-data-excel .info-credit-card-selected span {
      font-size: 1.2em; }
