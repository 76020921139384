.tick line {
  color: #eaeaea;
}

.domain {
  visibility: hidden;
}

.chart-tooltip {
  position: absolute;
  background-color: #7C4DFF;
  color: #fff;
  z-index: 1;
  border-radius: 8px;
  font-size: 10px;
  padding: 10px 10px;
  text-align: left;
}

.chart-tooltip p {
  margin: 0rem 0rem;
}

.chart-tooltip::before {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-top-color: #7C4DFF;
  bottom: -1.2rem;
  margin-left: auto;
  margin-right: auto;
  left: 40%;
}

.tooltipHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tooltipFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.infor {
  padding: 8px 0px 8px 0px;
}
