.date-picker-customized.datepicker-field {
  display: flex; }
  .date-picker-customized.datepicker-field > div {
    width: 100%; }
    .date-picker-customized.datepicker-field > div > div {
      position: absolute;
      left: -34 px;
      margin-left: 0; }
      .date-picker-customized.datepicker-field > div > div button {
        width: 32px;
        height: 32px; }
        .date-picker-customized.datepicker-field > div > div button span span {
          font-size: 1em; }
