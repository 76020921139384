.container-custom-budgets .content-custom-budgets .custom-budgets-paper .empty-table {
  display: flex;
  flex-direction: column; }
  .container-custom-budgets .content-custom-budgets .custom-budgets-paper .empty-table .mdi {
    font-size: 7em;
    margin-top: 40px;
    color: #9e9e9e; }
  .container-custom-budgets .content-custom-budgets .custom-budgets-paper .empty-table p,
  .container-custom-budgets .content-custom-budgets .custom-budgets-paper .empty-table h2 {
    margin: 60px 0;
    margin-top: 0px;
    color: #9e9e9e; }

.budgets .mdi-chevron-down:before {
  margin-right: 5px; }

.budgets button.change-tab {
  padding-left: 15px;
  height: 55px;
  text-transform: none; }
  .budgets button.change-tab.light {
    background-color: #6515dd; }
    .budgets button.change-tab.light:hover {
      background-color: #5512ba; }
  .budgets button.change-tab.dark {
    background-color: #7c4dff; }
    .budgets button.change-tab.dark:hover {
      background-color: #6933ff; }
  .budgets button.change-tab h2:before {
    position: absolute;
    right: 5px; }
  .budgets button.change-tab svg {
    color: #fff; }

.budgets .help {
  margin-left: 10px; }
  .budgets .help.light {
    color: #6515dd; }
    .budgets .help.light:hover {
      color: #5512ba; }
  .budgets .help.dark {
    color: #7c4dff; }
    .budgets .help.dark:hover {
      color: #6933ff; }

.budgets .actions-toolbar-btn button {
  font-weight: 900;
  box-shadow: 1.5px 1.5px 1px 0px rgba(0, 0, 0, 0.3);
  color: #7c4dff;
  padding: 9px 15px;
  font-size: 14px; }
  .budgets .actions-toolbar-btn button.mdi-plus::before {
    font-size: 17px;
    font-weight: bold; }
  .budgets .actions-toolbar-btn button .MuiButton-label {
    margin-left: 8px; }
  .budgets .actions-toolbar-btn button.light {
    background-color: #fff; }
  .budgets .actions-toolbar-btn button.dark {
    background-color: #333; }

.budgets .toolbar-actions {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.budgets .tabs-paper {
  margin: 21px 35px 0 30px; }

.budgets .tabs {
  min-height: 48px;
  height: 48px;
  border-radius: 25px; }
  .budgets .tabs .mdi {
    font-size: 26px; }

.budgets .content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .budgets .content .content-budgets {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1330px) {
      .budgets .content .content-budgets {
        display: -webkit-box; } }

.budgets thead,
.budgets tbody {
  position: relative; }
  .budgets thead tr,
  .budgets tbody tr {
    position: relative; }
    .budgets thead tr th:first-child,
    .budgets thead tr td:first-child,
    .budgets tbody tr th:first-child,
    .budgets tbody tr td:first-child {
      padding-left: 30px;
      padding-right: 10px; }
    .budgets thead tr td:last-child,
    .budgets tbody tr td:last-child {
      width: 30%; }
      @media (min-width: 1000px) {
        .budgets thead tr td:last-child,
        .budgets tbody tr td:last-child {
          width: 29%; } }
      .budgets thead tr td:last-child .last-cel,
      .budgets tbody tr td:last-child .last-cel {
        display: flex; }
        .budgets thead tr td:last-child .last-cel .chart,
        .budgets tbody tr td:last-child .last-cel .chart {
          margin-right: -18px; }
        .budgets thead tr td:last-child .last-cel .actions-btn-table,
        .budgets tbody tr td:last-child .last-cel .actions-btn-table {
          padding-right: 20px; }
        .budgets thead tr td:last-child .last-cel .actions-btn-table-total,
        .budgets tbody tr td:last-child .last-cel .actions-btn-table-total {
          padding-right: 13px; }
          .budgets thead tr td:last-child .last-cel .actions-btn-table-total > button > svg,
          .budgets tbody tr td:last-child .last-cel .actions-btn-table-total > button > svg {
            width: 24px;
            height: 24px; }
  .budgets thead .chart,
  .budgets tbody .chart {
    width: 68%;
    display: flex;
    align-items: center;
    position: relative; }
    .budgets thead .chart span,
    .budgets tbody .chart span {
      margin-left: 5px;
      width: 32%; }
    .budgets thead .chart .chart-budget,
    .budgets tbody .chart .chart-budget {
      width: 80%;
      background: #cfcfcf;
      height: 6px;
      border-radius: 3px;
      position: relative; }
      @media (min-width: 1000px) {
        .budgets thead .chart .chart-budget,
        .budgets tbody .chart .chart-budget {
          width: 100%; } }
    .budgets thead .chart .chart-percent,
    .budgets thead .chart .chart-percent-previst,
    .budgets tbody .chart .chart-percent,
    .budgets tbody .chart .chart-percent-previst {
      position: absolute;
      height: 6px;
      border-radius: 3px;
      width: 0;
      transition: 0.3s; }
    .budgets thead .chart .chart-percent,
    .budgets tbody .chart .chart-percent {
      z-index: 2; }
    .budgets thead .chart .chart-percent-previst,
    .budgets tbody .chart .chart-percent-previst {
      z-index: 1; }

.budgets .more-info {
  height: 0px;
  padding: 0;
  max-width: 700px;
  transition: 0.3s; }
  .budgets .more-info.open {
    height: 200px; }
    .budgets .more-info.open td,
    .budgets .more-info.open td:first-child {
      background: #eee;
      height: 200px; }
      .budgets .more-info.open td .progress,
      .budgets .more-info.open td:first-child .progress {
        margin-top: 70px; }
  .budgets .more-info td,
  .budgets .more-info td:first-child {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 0px;
    max-width: 700px;
    padding: 0;
    transition: 0.3s; }
    @media (min-width: 950px) {
      .budgets .more-info td,
      .budgets .more-info td:first-child {
        width: 800px; } }

.budgets .table-budgets .h20 {
  height: 20px; }

.budgets .table-budgets .h30 {
  height: 30px; }

.budgets .table-budgets .row-categories,
.budgets .table-budgets .row-description {
  display: flex;
  align-items: center; }
  .budgets .table-budgets .row-categories > div,
  .budgets .table-budgets .row-description > div {
    display: flex;
    flex-wrap: wrap; }
  .budgets .table-budgets .row-categories .tags-list,
  .budgets .table-budgets .row-description .tags-list {
    margin: 5px;
    height: 20px;
    background-color: #7c4dff;
    color: #fff;
    line-height: 1.4em; }
    .budgets .table-budgets .row-categories .tags-list span,
    .budgets .table-budgets .row-description .tags-list span {
      font-family: "Noto Sans";
      font-size: 0.875em; }

.budgets .table-budgets .category-icon-table {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  color: #ffffff; }

.budgets .cards-info {
  margin-top: 30px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1329px) {
    .budgets .cards-info {
      width: 100%; } }
  @media (min-width: 1330px) {
    .budgets .cards-info {
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-right: 0;
      width: 20%; } }
  .budgets .cards-info .card-recipes-month,
  .budgets .cards-info .card-planned-expenses,
  .budgets .cards-info .card-planned-balance,
  .budgets .cards-info .card-planned-economy {
    width: 210px; }
    .budgets .cards-info .card-recipes-month:not(:last-child),
    .budgets .cards-info .card-planned-expenses:not(:last-child),
    .budgets .cards-info .card-planned-balance:not(:last-child),
    .budgets .cards-info .card-planned-economy:not(:last-child) {
      margin-right: 18px; }
    @media (min-width: 1330px) {
      .budgets .cards-info .card-recipes-month,
      .budgets .cards-info .card-planned-expenses,
      .budgets .cards-info .card-planned-balance,
      .budgets .cards-info .card-planned-economy {
        margin-bottom: 10px;
        width: 100%;
        max-width: 300px; } }
  .budgets .cards-info .progress-card {
    color: #fff; }
  .budgets .cards-info .card-recipes-month .card-content {
    color: #4caf50; }
  .budgets .cards-info .card-planned-expenses .card-content {
    color: #f44336; }
  .budgets .cards-info .card-planned-balance .card-content {
    color: #6515dd; }
  .budgets .cards-info .card-planned-economy .card-content {
    color: #4caf50; }
  .budgets .cards-info .card-recipes-month .card-border-icon {
    background-color: #4caf50; }
  .budgets .cards-info .card-planned-expenses .card-border-icon {
    background-color: #f44336; }
  .budgets .cards-info .card-planned-balance .card-border-icon {
    background-color: #6515dd; }
  .budgets .cards-info .card-planned-economy .card-border-icon {
    background-color: #2e7d32; }

.btn-faq {
  font-size: 1.3rem !important; }

.menu-right {
  display: flex; }

.search {
  margin: 0px 15px;
  display: inline-block; }

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0; }

.switch-toolbar {
  width: 100%; }
  .switch-toolbar label {
    display: flex;
    justify-content: space-between; }

.container-custom-budgets {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px; }
  .container-custom-budgets .content-custom-budgets {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse; }
    @media (min-width: 1330px) {
      .container-custom-budgets .content-custom-budgets {
        flex-direction: row-reverse;
        display: -webkit-box; } }
    .container-custom-budgets .content-custom-budgets .custom-budgets-paper {
      margin: 0px 5px 0px 5px;
      padding-bottom: 10px;
      width: 100%;
      border-radius: 15px; }
      @media (min-width: 1330px) {
        .container-custom-budgets .content-custom-budgets .custom-budgets-paper {
          width: 100%; } }
      .container-custom-budgets .content-custom-budgets .custom-budgets-paper .toolbar-options {
        margin-top: -115px;
        max-width: 500px;
        display: flex;
        justify-content: flex-end;
        float: right;
        min-height: 32px;
        padding-top: 0;
        padding-right: 0;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px; }
        .container-custom-budgets .content-custom-budgets .custom-budgets-paper .toolbar-options span {
          font-size: 0.9em; }
        .container-custom-budgets .content-custom-budgets .custom-budgets-paper .toolbar-options .switch-toolbar p {
          font-weight: 500;
          text-transform: uppercase;
          color: #1d1d1d; }
      .container-custom-budgets .content-custom-budgets .custom-budgets-paper .progress {
        margin: 60px; }
      .container-custom-budgets .content-custom-budgets .custom-budgets-paper .categories {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        position: relative; }
        .container-custom-budgets .content-custom-budgets .custom-budgets-paper .categories .chip {
          font-size: 0.7em;
          margin: 4px;
          width: auto; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .categories .chip.category {
            color: #ffffff; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .categories .chip.more-categories {
            font-weight: bold;
            height: 18px;
            padding-bottom: 6px;
            font-size: 1em; }
      .container-custom-budgets .content-custom-budgets .custom-budgets-paper .empty-table {
        height: 300px;
        justify-content: center; }
        .container-custom-budgets .content-custom-budgets .custom-budgets-paper .empty-table .label-budgets-expired-empty-list {
          margin: 20px 0px 0px; }
      .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list {
        margin-top: 28px; }
        .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item {
          position: relative;
          display: flex;
          justify-content: center;
          border-radius: 15px;
          transition: 0.3s; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item > div {
            width: 100%;
            padding: 16px;
            padding-top: 14px;
            padding-right: 10px; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info h6 {
            text-align: initial;
            margin: 5px 0px; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-header .card-title {
              display: flex;
              justify-content: flex-start;
              align-items: center; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-header .mdi {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #eee;
              width: 32px;
              height: 32px;
              min-width: 32px;
              min-height: 32px;
              border-radius: 18px;
              font-size: 1.2em;
              margin-right: 5px; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-header .title {
              text-align: left;
              font-size: 1em;
              font-weight: 600; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .flag {
            width: 50px;
            margin-right: 6px; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .color-account {
            width: 30px;
            height: 30px;
            margin-right: 10px; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info {
            display: flex;
            flex-direction: column;
            width: 100%; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .goal-category,
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .spent-category,
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .remain-category {
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .goal-category span:first-child,
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .spent-category span:first-child,
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .remain-category span:first-child {
                font-weight: 500; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .period,
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .expires {
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
              font-weight: 500;
              color: gray;
              font-size: 0.7em; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .expires {
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
              font-weight: bold;
              color: gray;
              font-size: 0.875em; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              margin-top: 8px; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-budget {
                background: #cfcfcf;
                height: 10px;
                border-radius: 10px;
                position: relative;
                margin: 5px 0px 10px;
                width: 100%; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-values {
                display: flex;
                justify-content: space-between;
                color: #999;
                font-size: 14px;
                width: 100%; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-percent {
                z-index: 2; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-percent-previst {
                z-index: 1; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-percent, .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-percent-previst {
                position: absolute;
                height: 10px;
                border-radius: 10px;
                width: 0;
                transition: 0.3s; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-perc {
                display: flex;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                margin-bottom: 5px; }
                .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-perc span:first-child {
                  align-items: flex-start; }
                .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .chart .chart-perc span:last-child {
                  align-items: flex-end; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .metas {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;
              padding-right: 6px; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .metas h6 {
                margin: 0;
                line-height: 1.2rem; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .metas .col {
                display: flex;
                flex-direction: column;
                align-items: center; }
                .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-info .metas .col span:last-child {
                  margin-top: 4px;
                  font-size: 0.9em; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-actions {
            display: flex;
            justify-content: flex-end;
            margin: 0; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-actions .tooltip-top div[role="tooltip"] {
              margin: 0; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-actions button {
              width: 32px;
              height: 32px;
              padding: 5px; }
              .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-card-item .card-actions button svg {
                font-size: 20px; }
        .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-add {
          display: flex;
          justify-content: center;
          transition: 0.3s;
          align-items: center;
          border-radius: 15px;
          min-height: 263px;
          cursor: pointer;
          opacity: 0.7; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-add:hover {
            opacity: 1; }
          .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-add > div {
            display: flex;
            flex-direction: column;
            padding: 16px;
            font-size: 1.2em; }
            .container-custom-budgets .content-custom-budgets .custom-budgets-paper .grid-list .custom-budget-add > div .plus {
              font-size: 3em;
              display: block;
              margin: auto; }
    .container-custom-budgets .content-custom-budgets .cards-info {
      margin: 0;
      margin-top: 30px;
      display: flex;
      justify-content: space-between; }
      @media (max-width: 1329px) {
        .container-custom-budgets .content-custom-budgets .cards-info {
          width: 100%; } }
      @media (min-width: 1330px) {
        .container-custom-budgets .content-custom-budgets .cards-info {
          flex-direction: column;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: 20px;
          margin-right: 0;
          width: 20%; } }
      .container-custom-budgets .content-custom-budgets .cards-info .card-recipes-month,
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-expenses,
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-balance,
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-economy {
        width: calc(25% - 15px); }
        @media (min-width: 1330px) {
          .container-custom-budgets .content-custom-budgets .cards-info .card-recipes-month,
          .container-custom-budgets .content-custom-budgets .cards-info .card-planned-expenses,
          .container-custom-budgets .content-custom-budgets .cards-info .card-planned-balance,
          .container-custom-budgets .content-custom-budgets .cards-info .card-planned-economy {
            margin-bottom: 10px;
            width: 100%;
            max-width: 300px; } }
      .container-custom-budgets .content-custom-budgets .cards-info .progress-card {
        color: #fff; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-recipes-month .card-content {
        color: #4caf50; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-expenses .card-content {
        color: #f44336; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-balance .card-content {
        color: #6515dd; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-economy .card-content {
        color: #4caf50; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-recipes-month .card-border-icon {
        background-color: #4caf50; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-expenses .card-border-icon {
        background-color: #f44336; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-balance .card-border-icon {
        background-color: #6515dd; }
      .container-custom-budgets .content-custom-budgets .cards-info .card-planned-economy .card-border-icon {
        background-color: #2e7d32; }

.more-categories-modal .MuiDialog-paperWidthSm {
  width: 100%; }

.more-categories-modal .more-categories {
  display: flex;
  min-height: 100px; }
  .more-categories-modal .more-categories .chip.category:first-child {
    margin-top: 0; }
  .more-categories-modal .more-categories .chip.category {
    color: #ffffff;
    font-size: 0.8em;
    margin: 4px;
    width: auto; }
