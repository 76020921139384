.calendar .calendar-title {
  margin-top: -20px; }

.calendar .content {
  display: flex;
  margin: 1rem 20px; }

.calendar .card-column-calendar {
  width: 44%;
  margin-right: 20px;
  margin-left: 10px; }
  .calendar .card-column-calendar .card-calendar {
    border-radius: 15px; }

.calendar .card-column-details {
  margin-right: 20px;
  width: 54%;
  border-radius: 15px; }
  .calendar .card-column-details .details-card-wrapper {
    border-radius: 15px; }
    .calendar .card-column-details .details-card-wrapper.light {
      background-color: #f9f9f9; }
    .calendar .card-column-details .details-card-wrapper.dark {
      background-color: #424242; }

.calendar .col {
  display: table;
  flex-direction: column; }

.calendar .card-title {
  font-size: 1.1em;
  font-weight: 400;
  margin-right: 16px;
  text-align: left;
  padding: 20px; }

.calendar .card-content {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; }

.calendar .color-expense {
  color: #f44336; }

.calendar .color-income {
  color: #2e7d32; }

.calendar .font-tooltip-observacao {
  vertical-align: -webkit-baseline-middle; }

.calendar .font-tooltip-observacao span {
  color: #2196f3; }

.calendar .font-tooltip-observacao span svg {
  width: 18px;
  height: 18px; }

.calendar .total {
  font-size: 0.8rem;
  font-weight: 600; }

.calendar .totalValue {
  font-size: 0.8rem;
  font-weight: 600; }

.calendar .action-buttons button {
  width: 25px;
  height: 25px; }
  .calendar .action-buttons button svg {
    width: 18px;
    height: 18px; }

.calendar .action-buttons .chip-cartao {
  background-color: #009688;
  color: #fff; }

.calendar .toolbar-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 32px;
  padding: 0; }

.calendar .card-details {
  box-shadow: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; }
